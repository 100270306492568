import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import style from "./index.module.css";
import styleUi from "./PhoneOptionModal.module.scss";
import allStyle from "../desktop/global/all.module.scss";
import { v4 } from "uuid";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';

const PhoneOptionModal = ({ setModalType, arg }) => {
    const [inProp, setInProp] = useState(false);
    useEffect(() => {
        setInProp(true);
    }, [])

    return (
        <div className={style.modal}>
            <CSSTransition in={inProp} timeout={{
                appear: 0,
                enter: 300,
                exit: 0,
            }} classNames={{
                enterActive: style.OpacityEnterActive,
                enterDone: style.OpacityEnterDone,
                exitActive: style.OpacityExit,
                exitDone: style.OpacityExitActive
            }}
            >
                <div className={`${style.Opacity} ${style.modal}`} >
                    <CSSTransition in={inProp} timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 0,
                    }} classNames={{
                        enterActive: style.PopupPageEnterActive,
                        enterDone: style.PopupPageEnterDone,
                        exitActive: style.PopupPageExit,
                        exitDone: style.PopupPageExitActive
                    }}
                        onExited={async () => {
                            await new Promise(resolve => requestAnimationFrame(resolve));
                            setModalType(prev => prev.filter(v => v.cls !== "PhoneOptionModal"));
                        }}
                    >
                        <div className={`${style.PopupPage} ${style.page}`} id="background" onClick={event => {
                            if (event.target.id == "background") {
                                //setInProp(false)
                            }
                        }}>
                            <div className={styleUi.app}>
                                <div className={styleUi.btns}>
                                    {
                                        arg.btns.map(btn => {
                                            return <div className={allStyle.btnWhite} key={v4()} onClick={e => {
                                                setInProp(false);
                                                btn.cb();
                                            }}>{btn.label}</div>
                                        })
                                    }
                                </div>
                                <img className={styleUi.close} src="./images/close_s.png" onClick={e => { setInProp(false) }} />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
}

export default memo(PhoneOptionModal);