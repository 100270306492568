import * as THREE from "three";
export default async (scene, comm,settingObj,renderFn,product,preloadBalls) => {
    var n = settingObj.n+1;
    var r1 = settingObj.r1;
    var sn = n;
    var x = 0;
    var z = 0;
    var rotation = 0;
    var _d1=r1===0.6?1.5:0.9;
    var _d2=r1===0.6?1.8:1.5;
    var _ang = 360 / (_d1+sn+((1-r1)*2));//1.5   0.9
    console.log("xxxxx",r1,_ang)
    var deg_to_pi = Math.PI * 2 / 360;
    var r2 = (r1 / Math.sin(_ang / 2 * deg_to_pi));
    settingObj.r2 = r2;
    var ang = _ang;
    var arg1 = 0;
    var _r1 = 0;
    //1中心珠,2一般珠,3連接珠
    for (var i = 0; i < sn; i++) {
      var deg = ang * deg_to_pi;
      var nid = 0;
      
      if (0 == i) {
        arg1 = 1;
        nid = 192;
        _r1=1;
        ang += (_ang*(_d2+(1-r1)));  //1.8   1.5
      }
      else {
        arg1 = 2;
        nid = 1001;
        _r1=r1;
        ang += _ang;
      }
      x = r2 * Math.cos(deg);
      z = r2 * Math.sin(deg);
      rotation = -deg;
      scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls,i,nid), i, x, r1, z, _r1, rotation, arg1));
    }
    scene.rotation.y = (-90 + _ang) * deg_to_pi;

    const geometry = new THREE.SphereGeometry(r2, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.visible=false;
    scene.add(sphere);
    settingObj.target = sphere;

    renderFn();
};
