import * as THREE from "three";
export default async (scene, comm, settingObj, renderFn,product,preloadBalls) => {
    var n = settingObj.n;
    var r1 = settingObj.r1;
    var _ang = (360 / n);//圓周只算n-1顆
    var deg_to_pi = Math.PI * 2 / 360;
    var r2 = (r1 / Math.sin(_ang / 2 * deg_to_pi));
    settingObj.r2 = r2;
    var model = await comm.loadGltf("baseModel", 999, 0, r1, -2, r1, 0, 9);
    
   /*  var index = product.nList.indexOf(n.toString())
    var _nid = product.argList[index];
    console.log("_nid", _nid);
    _nid = parseInt(_nid);
    var model = await comm.loadGltf(_nid, 999, 0, r1, 0, r1, 0, 999);
    model.arg = 9; */

    const geometry = new THREE.SphereGeometry(r2*r1, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.visible=false;
    scene.add(sphere);
    settingObj.target = sphere;

    scene.add(model)
    model.traverse(async function (child) {
        if (child && child.isMesh) {
            var worldPosition = new THREE.Vector3();
            child.getWorldPosition(worldPosition)
            child.updateMatrixWorld();
            var quaternion = new THREE.Quaternion()
            child.getWorldQuaternion(quaternion)
            let rotation = new THREE.Euler()
            rotation.setFromQuaternion(quaternion)
            if (worldPosition.x <= 0) {
                rotation.y = rotation.y - Math.PI;
            }
            if (child.name == "Necklace") {
            }
            else {
                const nameArr = child.name.split("_");
                let arg = 0;
                if (nameArr[0] == "Beaded") {
                    arg = 2;
                }
                else if (child.name == "CenterBeaded_001") {
                    arg = 1;
                }
                else {
                    //左右墜飾
                    arg = 2;
                }
                child.scale.set(0, 0, 0);
                //中間是1，左邊是2，右邊是3

                if (child.name == "CenterBeaded_001") {
                    scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls,parseInt(nameArr[1]),116), parseInt(nameArr[1]), worldPosition.x, worldPosition.y, worldPosition.z, r1 * r1, rotation.y, arg));
                }
                else {
                    scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls,parseInt(nameArr[1]) + 100,1001), parseInt(nameArr[1]) + 100, worldPosition.x, worldPosition.y, worldPosition.z, r1 * r1, rotation.y, arg));
                }
                renderFn();
            }

        }

    });


};
