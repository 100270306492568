import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import style from "./index.module.css";
import styleUi from "./RingItemList.module.scss";
import { v4 } from "uuid";
import { CSSTransition } from 'react-transition-group';
import { BackgroundDiv } from "./../desktop/global/Styleds";
import { useTranslation } from "react-i18next";
const RingItemList = ({ setModalType, arg }) => {
    const [inProp, setInProp] = useState(false);
    const [imgs, set_imgs] = useState([]);
    const [data, set_data] = useState(null);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setInProp(true);
        set_data(arg.tempSave);
        set_imgs(arg.allImage);
    }, [])

    return (
        <div className={style.modal}>
            <CSSTransition in={inProp} timeout={{
                appear: 0,
                enter: 300,
                exit: 0,
            }} classNames={{
                enterActive: style.OpacityEnterActive,
                enterDone: style.OpacityEnterDone,
                exitActive: style.OpacityExit,
                exitDone: style.OpacityExitActive
            }}
            >
                <div className={`${style.Opacity} ${style.modal}`} >
                    <CSSTransition in={inProp} timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 0,
                    }} classNames={{
                        enterActive: style.PopupPageEnterActive,
                        enterDone: style.PopupPageEnterDone,
                        exitActive: style.PopupPageExit,
                        exitDone: style.PopupPageExitActive
                    }}
                        onExited={async () => {
                            await new Promise(resolve => requestAnimationFrame(resolve));
                            setModalType(prev => (prev.slice(0, -1)));
                        }}
                    >
                        <div className={`${style.PopupPage} ${style.page}`} id="background" onClick={event => {
                            if (event.target.id == "background") {
                                setInProp(false)
                            }
                        }}>
                            <div className={styleUi.app}>
                                <h1>{t('ADD-ONS')}</h1>
                                <div className={styleUi.imgs}>
                                    {imgs.reverse().map(img => {
                                        return <BackgroundDiv img={img} className={styleUi.imgBox} />
                                    })}
                                </div>
                                <div className={styleUi.info}>
                                    <h1>{t('ADD-ONS')}</h1>
                                    <div >
                                        <div className={styleUi.titleBox}>
                                            <span>{t('ringListTitle_1')}: </span>
                                            {/* <span>$1800</span> */}
                                        </div>
                                        <div className={styleUi.detail}>
                                            <span>{t('ringListSubTitle_1')}: {data?.selBall[0]?.type}</span>
                                            <span>{t('ringListSubTitle_2')}: {data?.selBall[0]?.shape}</span>
                                            <span>{`${t('ringListSubTitle_3')}: ${data?.selBall[0]?.name}`}</span>
                                            {/* <span>{`${t('ringListSubTitle_3')}: ${parseFloat(data?.selBall[0]?.arg).toFixed(1) / 10}ct`}</span> */}
                                        </div>
                                        <div className={styleUi.hr} />
                                        <div className={styleUi.titleBox}>
                                            <span>{t('ringListTitle_2')}: </span>
                                            {/* <span>$30</span> */}
                                        </div>
                                        <div className={styleUi.detail}>
                                            <span>{data?.selBall[3]?.name}</span>
                                        </div>
                                    </div>
                                    <span className={styleUi.total}>{/* TOTAL PRICE: $3030 */}</span>
                                </div>
                                <img className={styleUi.close} src="./images/close.png" onClick={e => { setInProp(false) }} />
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
}

export default memo(RingItemList);