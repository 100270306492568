import { useState, useEffect, useRef, Fragment } from "react";
import Header from "../../global/components/Header"
import style from "./index.module.scss";
import { Link, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'
import MessageBtn from "../../global/components/MessageBtn";
import NoticeBar from "../../global/components/NoticeBar";
import Myselect from "../../global/components/Myselect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons'
import { fetchPost } from "../../../comm/comm";
import { useTranslation } from "react-i18next";
import allStyle from "../../global/all.module.scss";
import { Ball, Item, customStyles, previewModalStyles, LoadingBar } from "../../global/Styleds";
const Priview = ({ cart, currectProduct, setModalType }) => {
    const { t, i18n } = useTranslation();
    let { productsName, pid, arg, bead } = useParams();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [modalIsOpen, setIsOpen] = useState(false);
    const [balls, setBalls] = useState([{}]);
    const [baseBall, setBaseBall] = useState({});
    const [middleBall, setMiddleBall] = useState({});
    const [basePrice, setBasePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        const basePrice = localStorage.getItem('basePrice');
        setBasePrice(parseInt(basePrice));
        const ballStr = localStorage.getItem('ball');
        console.log("arg,bead",arg,bead)
        if (ballStr) {
            const ballData = JSON.parse(ballStr);
            const sum = ballData.reduce((accumulator, object) => {
                return accumulator + object.price;
            }, 0);
            setTotalPrice(sum + parseInt(basePrice));
            const baseBall = ballData.filter(v => v.arg1 === 5);
            const middleBall = ballData.filter(v => v.arg1 === 1);
            const otherBall = ballData.filter(v => (v.arg1 == 2 || v.arg1 == 3 || v.arg1 == 4));
            console.log(baseBall)
            setBaseBall(baseBall[0]);
            setMiddleBall(middleBall[0]);
            setBalls(otherBall);
        }
        console.log("currectProduct", currectProduct)
    }, [])

    function ShowItem(props) {
        return <Item border={props.showBorder}>
            <Ball img={props.item.image}></Ball>
            <div>
                <p>{props.item.name}</p>
                <p>${props.item.price}</p>
            </div>
        </Item>
    }
    function openModal(item) {

        setIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }
    function addCart() {
        //v1 v2 v3 v6 v4 v5
        var laser = [...balls, middleBall].reduce((arr, ball) => {
            if (ball.laserData)
                arr.push({ pos: ball.pos, font: ball.laserData.font, message: ball.laserData.message })
            return arr;
        }, []);
        const obj = {
            "beadSize": bead,
            "wristSize": arg,
            "image": localStorage.getItem("tmppng").substring(22),
            "middleBead": middleBall.id,
            "otherBeads": balls.map(v => v.id),
            "laser": laser
        }
        if (pid === "v3")
            obj.stringColor = baseBall.id;
        else if (pid === "v4" || pid === "v5") {
            delete obj.wristSize;
            obj.necklaceSize = arg;
        }
        console.log(obj);
        const fnArr = {}
        setModalType(prev => [...prev, {
            cls: "Loading", arg: { fnArr: fnArr, processTime: 1000 }
        }])
        fetchPost(`/cart/${pid}?lang=${i18n.language}`, obj).then(res => {
            if (res.redirect) {
                fnArr.doCloseFn();
                if (isMobile) 
                    document.location.href=res.redirect;
                else
                    window.open(res.redirect, '_blank');
            }
            else {
                fnArr.doCloseFn();
                console.log("res", res);
            }
        }).catch(err => {
            fnArr.doCloseFn();
            console.log("res error", err);
        });
    }

    //
    return (
        <div className={style.app}>
            <NoticeBar />
            <Header setModalType={setModalType}  cart={cart} />
            <div className={style.view}>
                <div className={style.topBtns} >
                    <div to={-1} className={style.back} onClick={e => {
                        document.location.href = `#/editor/${productsName}/${pid}/${arg}/${bead}`;
                        document.location.reload();
                    }}><p><FontAwesomeIcon icon={faChevronLeft} /> {t('backToEdit')}</p></div>
                    <Link to={'/'} className={style.close} ><FontAwesomeIcon icon={faClose} /></Link>
                </div>

                <img src={localStorage.getItem("tmppng")} />
                <h1>{`${t('customAzuro').format(localStorage.getItemByLang(i18n.language, "productName", pid).text)}`}</h1>
                <h2>{t('totalPrice')}: ${totalPrice}</h2>
                <h2 onClick={openModal}>{t('seeItemList')}<FontAwesomeIcon icon={faChevronRight} /></h2>

                <button className={allStyle.btnBlack} onClick={e => { addCart() }}>{t('addToCart')}</button>
            </div>
            <MessageBtn />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={isMobile ? previewModalStyles : customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal"
            >
                <div className={style.modal_info}>
                    <div>
                        <h1>{t('baseProduct')}</h1>
                        <h3>{localStorage.getItem("productName")}</h3>
                        <p>{t('basePrice')}: ${basePrice}</p>
                        <p>{t('wristsize')}: {arg}</p>
                        <p>{t('beadsize')}: {bead}</p>
                        {baseBall &&
                            <p>{t('baseModel')}: {baseBall.name} ${baseBall.price}</p>
                        }
                    </div>
                    <div>
                        <h1>{t('middleBead')}</h1>
                        <ShowItem showBorder={false} item={middleBall} />
                    </div>

                </div>
                <div className={style.modal_detail}>
                    <h1>{t('ADD-ONS')}</h1>
                    <div>
                        {
                            balls.map(item => {
                                return <ShowItem key={v4()} item={item} showBorder={true} />
                            })
                        }
                    </div>
                    <h2>{t('totalPrice')}: ${totalPrice}</h2>
                </div>
                <FontAwesomeIcon className={style.modal_close} icon={faClose} onClick={closeModal} />
            </Modal>
        </div>
    );
}

export default Priview;