import * as THREE from "three";
export default async (scene, comm, settingObj, renderFn, product, preloadBalls) => {
    var n = settingObj.n+1;
    var r1 = settingObj.r1;
    var exN = 2;
    if (n > 21)
        exN = 3;
    var sn = n + exN;
    var centerN = parseInt(n / 2) + exN;
    var x = 0;
    var z = 0;
    var rotation = 0;
    var _ang = 360 / (sn+((1-r1)*2));
    var deg_to_pi = Math.PI * 2 / 360;
    var r2 = (r1 / Math.sin(_ang / 2 * deg_to_pi));
    settingObj.r2 = r2;
    var shape = 1;
    var ang = _ang * ((exN == 2) ? 1.5 : 2);
    console.log("centerN", r1,centerN);
    var arg1 = 0;
    var _r1=0;
    //1中心珠,2一般珠,3連接珠,4尾珠,9繩子
    for (var i = exN; i < sn; i++) {
        var deg = ang * deg_to_pi;
        var nid = 0;
        if (centerN == i) {
            arg1 = 1;
            nid = 117;
            _r1=1;
            ang += (_ang*(1+(1-r1)));
        }
        else {
            arg1 = 2;
            nid = 1001;
            _r1=r1;
            if (centerN-1 == i) 
            ang += (_ang*(1+(1-r1)));
            else
            ang += _ang;
        }
        x = r2 * Math.cos(deg);
        z = r2 * Math.sin(deg);

        rotation = -deg;
        scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls, i, nid), i, x, r1, z, _r1, rotation, arg1));
        shape++;
    }
    var obj = await comm.loadGltf("baseModel", 999, 0, 0, 0, r2 , 0, 5);
    scene.add(obj);
    obj.traverse(async function (child) {
        if (child && child.isMesh) {
            var worldPosition = new THREE.Vector3();
            child.getWorldPosition(worldPosition)
            child.updateMatrixWorld();
            var quaternion = new THREE.Quaternion()
            child.getWorldQuaternion(quaternion)
            let rotation = new THREE.Euler()
            rotation.setFromQuaternion(quaternion)
            if (worldPosition.x <= 0) {
                rotation.y = rotation.y - Math.PI;
            }
            child.visible=(child.name=="1_5_BeadedString_01");
            if(child.name=="1_5_BeadedString_r4")
            {
                child.visible=true;
                scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls, i + 1, 1001), i + 1, -worldPosition.z, r1, worldPosition.x, .6, 0, 4));
            }
            if(child.name=="1_5_BeadedString_r9")
            {
                child.visible=true;
            }
            if(child.name=="1_5_BeadedString_l4")
            {
                child.visible=true;
                scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls, i + 2, 1001), i + 2, -worldPosition.z, r1, worldPosition.x, .6, 0, 4));
            }
            if(child.name=="1_5_BeadedString_l9")
            {
                child.visible=true;
            }
        }

    });

    var baseModelRecordId = comm.loadTempBall(preloadBalls, 999, 0);
    console.log(baseModelRecordId);//如果有記錄就會ret >0 ，ret是id，用id去找出該object的effects，來套上去
    //先寫死 
    if (baseModelRecordId == 114) {
        obj.name = "BLACK";
        obj.nid = 114;
        obj.price = 1;
        obj.image = "114";
        comm.changeEmissiveColor(obj, { emissive: "000000" });
    }
    else if (baseModelRecordId == 115) {
        obj.name = "RED";
        obj.nid = 115;
        obj.price = 1;
        obj.image = "115";
        comm.changeEmissiveColor(obj, { emissive: "ff0000" });
    }
    obj.rotation.y = -3.14 / 2;
    obj.position.y = r1;
    console.log("nnnn",n)
    /* if (n % 2 == 0) {
        scene.rotation.y = (-270 - _ang / 2) * deg_to_pi;
    }
    else { */
        scene.rotation.y = -270 * deg_to_pi;
    //}
    const geometry = new THREE.SphereGeometry(r2, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.visible = false;
    scene.add(sphere);
    settingObj.target = sphere;
    renderFn();
};
