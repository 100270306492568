import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import style from "./index.module.css";
import styleUi from "./TeachModal.module.scss";
import allStyle from "../desktop/global/all.module.scss";
import { v4 } from "uuid";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from "react-i18next";

const TeachModal = ({ setModalType, arg }) => {
    const { t, i18n } = useTranslation();
    const [inProp, setInProp] = useState(false);
    const [isCheck, set_isCheck] = useState(false);
    useEffect(() => {
        let _pid = arg.pid;
        if (["r1", "r2", "r3"].includes(arg.pid))
            _pid = "rings";
        if (localStorage.getItemByLang(i18n.language, "autoShowTechModal", _pid))
            set_isCheck(true);
        setInProp(true);
    }, [])

    return (
        <div className={style.modal}>
            <CSSTransition in={inProp} timeout={{
                appear: 0,
                enter: 300,
                exit: 0,
            }} classNames={{
                enterActive: style.OpacityEnterActive,
                enterDone: style.OpacityEnterDone,
                exitActive: style.OpacityExit,
                exitDone: style.OpacityExitActive
            }}
            >
                <div className={`${style.Opacity} ${style.modal}`} >
                    <CSSTransition in={inProp} timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 0,
                    }} classNames={{
                        enterActive: style.PopupPageEnterActive,
                        enterDone: style.PopupPageEnterDone,
                        exitActive: style.PopupPageExit,
                        exitDone: style.PopupPageExitActive
                    }}
                        onExited={async () => {
                            await new Promise(resolve => requestAnimationFrame(resolve));
                            setModalType(prev => (prev.slice(0, -1)));
                        }}
                    >
                        <div className={`${style.PopupPage} ${style.page}`} id="background" onClick={event => {
                            if (event.target.id == "background") {
                                //setInProp(false)
                            }
                        }}>
                            <div className={styleUi.app}>
                                <h1>{t('INSTRUCTION')}</h1>
                                <div className={styleUi.pic}>
                                    <div>
                                        <img src={`./images/${arg?.productsName}/rotate.png`} />
                                        <span>{t('instructionRotate')}</span>
                                    </div>
                                    <div>

                                        <img src={`./images/${arg?.productsName}/move.png`} />
                                        <span>{t('instructionMove')}</span>
                                    </div>
                                    <div>
                                        <img src={`./images/${arg?.productsName}/angle.png`} />
                                        <span>{t('instructionAngle')}</span>
                                    </div>
                                </div>
                                <div className={styleUi.checkDiv}>
                                    <input id='autoShow' type='checkbox' checked={isCheck} onChange={e => {
                                        set_isCheck(e.target.checked);
                                    }} />
                                    <label htmlFor='autoShow'>{t('instructionHide')}</label>
                                </div>
                                <button onClick={e => {
                                    let _pid = arg.pid;
                                    if (["r1", "r2", "r3"].includes(arg.pid))
                                        _pid = "rings";
                                    console.log("_pid", i18n.language, _pid, isCheck)
                                    localStorage.setItemByLang(i18n.language, "autoShowTechModal", _pid, isCheck ? "true" : "");
                                    arg.cb();
                                    setInProp(false);
                                }}>{t('Gotit')}</button>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
}

export default memo(TeachModal);