import { useState, useEffect, useRef, useCallback, useReducer } from "react";
import React, { Component, Fragment } from "react";
import { Canvas } from "./components/canvas"
import style from "./index.module.scss";
import "./index.css";
import Buttons from "./components/Buttons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import MessageBtn from "../../global/components/MessageBtn";
import Modal from 'react-modal';
import { v4 } from "uuid";
import ReactCanvas from "./reactCanvas";
import LaserCustom from "../../../Modal/LaserCoustom";
import { useTranslation } from "react-i18next";
import { fetchGet } from "../../../comm/comm";
import { customStyles, customStyles2, Ball, LoadingBar, DivH } from "../../global/Styleds";
import { CustomEventManager } from "../../global/CustomEventManager";
import TeachManager from "./TeachManager";
import { useMediaQuery } from 'react-responsive'
import { HexColorPicker } from "react-colorful";
const customEventManager = new CustomEventManager();
const Editor = ({ cart, setModalType }) => {
    const { t, i18n } = useTranslation();
    let { productsName, pid, arg, bead } = useParams();
    const threeRef = useRef();
    const previewBtnRef = useRef();
    const [btnEnable, setBtnEnable] = useState(true);
    const [isLoading, set_isLoading] = useState(true);
    const dragBall = useRef({ nid: 0, arg1: 0 });
    const isLoad = useRef(false);
    const [productData, setProductData] = useState(null);
    const [buttonsData, setButtons] = useState([]);
    const [selBall, setSelBall] = useState([]);//[price,id]
    const [tagData, setTags] = useState([]);
    const [ballArr, setBall] = useState([]);
    const [loadingDone, setLoadingDone] = useState(0);

    const [selItem, setSelItem] = useState({ item: { image: "", setting: { bead_size: [], wrist_size: [] } } });//對應下面的各種size，但這裡不用全列出來，反正是拿 server的值塞進來
    //select arg
    const laserData = useRef([]);
    const preloadBalls = useRef([]);

    //teaching
    const [laserModalData, set_laserModalData] = useState(null);
    const [teachTextA, setTeachTextA] = useState("");
    const canvasTmp = useRef({ obj: {} });
    //modal
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [innerHeight, set_innerHeight] = useState(window.innerHeight);
    const beadsRef = useRef([]);
    const [color, setColor] = useState('white')
    useEffect(() => {
        //i18n.changeLanguage(localStorage.getItem("lang"));
        console.log("=============i18n.language", i18n.language)
        if (i18n.language)
            getData();
    }, [i18n.language]);
    useEffect(() => {
        const beadsStr = localStorage.getItem("beads");
        if (beadsStr) {
            beadsRef.current = JSON.parse(beadsStr);
        }
        const handleResize = () => {
            set_innerHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        document.body.style.overflow = 'hidden';
        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.style.overflow = 'auto'; // 或者恢复到您希望的默认值
        };
    }, []);
    function getThreeCanvasHeight() {
        if (isMobile) {
            return (innerHeight * 0.82) - 70;
        }
        else {
            return (innerHeight - 244);//要先減掉 下方的254px+20px的保留空間
        }
    }
    function renameProperty(obj, oldName, newName) {
        for (let key in obj) {
            if (key === oldName) {
                obj[newName] = obj[key];
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                renameProperty(obj[key], oldName, newName);
            }
        }
    }
    function updateBeadName(result, beads) {
        for (var i = 0; i < result.toggleOptions.length; i++) {
            for (var j = 0; j < result.toggleOptions[i].tags.length; j++) {
                for (var k = 0; k < result.toggleOptions[i].tags[j].beads.length; k++) {
                    const bead = result.toggleOptions[i].tags[j].beads[k];
                    const obj = beads.find(v => v.id === bead.id);
                    if (obj)
                        bead.name = obj.name;
                    else
                        bead.name = "無資料";
                }
            }
        }

    }
    function getData() {
        let url = "";
        if (productsName === "bracelets")
            url = `/${productsName}/${pid}/design/wrist/${arg}/bead/${bead}`;
        else if (productsName === "necklaces")
            url = `/${productsName}/${pid}/design/necklaceLength/${arg}/bead/${bead}`;
        else if (productsName === "ring")
            url = `/rings/try-on`;
        else if (productsName === "cuff")
            url = `/cuffs/v7/design`;
        else if (productsName === "cufflinks")
            url = `/cufflinks/v8/design`;
        else if (productsName === "tiebar")
            url = `/tiebars/v9/design`;
        fetchGet(url, {}, i18n.language, 10)
            .then(result => {
                if (productsName == "ring") {
                    renameProperty(result, 'items', 'beads');
                    const typeName=result.toggleOptions[0].tags[0].beads[0].name;
                    const shapeName=result.toggleOptions[0].tags[1].beads[0].name;
                    result.toggleOptions[0].tags[2].beads.forEach(v=>{
                        v.type=typeName;
                        v.shape=shapeName;
                    })
                    result.toggleOptions[0].tags.splice(0,2);
                }
                else if (productsName == "cuff") {
                    //result.toggleOptions[1].tags[0].name = "Size";
                    /*  const _ = result.toggleOptions[1].tags[0].items.map((v, k) => {
                         return { price: 0, name: v, id: k }
                     });
                     console.log("xxxxxx",_)
                     result.toggleOptions[1].tags[0].items = _; */
                    renameProperty(result, 'items', 'beads');
                }
                else if (productsName == "cufflinks") {
                    renameProperty(result, 'items', 'beads');
                }
                else if (productsName == "tiebar") {
                    renameProperty(result, 'items', 'beads');
                }
                if (productsName !== "ring" && i18n.language === "zh")
                    updateBeadName(result, beadsRef.current);
                setProductData(result);
                let buttonsData = [];
                let tags = [];
                for (var i = 0; i < result.toggleOptions.length; i++) {
                    buttonsData.push({ id: result.toggleOptions[i].name, displayName: result.toggleOptions[i].displayName, name: result.toggleOptions[i].name, selected: i == 0, tags: result.toggleOptions[i].tags.map(v => v.name) });
                    if (i == 0) {
                        for (var j = 0; j < result.toggleOptions[i].tags.length; j++) {
                            tags.push({ toggleOption: result.toggleOptions[i].name, displayName: result.toggleOptions[i].displayName, name: result.toggleOptions[i].tags[j].name, selected: j == 0 });
                        }
                    }
                }
                setButtons(buttonsData);
                setTags(tags);
                for (var i = 0; i < result.toggleOptions.length; i++) {
                    if (result.toggleOptions[i].name == buttonsData[0].id) {
                        for (var j = 0; j < result.toggleOptions[i].tags.length; j++) {
                            if (result.toggleOptions[i].tags[j].name == tags[0].name) {
                                setBall(result.toggleOptions[i].tags[j].beads);
                                break;
                            }
                        }
                    }
                }
                const setting = { v: pid, _v: pid, r1: parseInt(bead) / 10, n: result.beadNumber };
                switchDragBallArg1(result.toggleOptions[0].type);
                const tmp_selBall = getLoad(result);
                console.log("++++++++++++++++++++++++tmp_selBall", tmp_selBall)
                preloadBalls.current = tmp_selBall;
                setSelBall(tmp_selBall);
                //last teach
                if (!(productsName === "ring" || productsName === "cuff" || productsName === "cufflinks" || productsName === "tiebar"))
                    canvasTmp.obj = new Canvas(threeRef, setLoadingDone, setBall, setting, result, dragBall, setSelBall, toPreview, preloadBalls.current, changeBtn, previewBtnRef, buttonsData);
            });
        return () => {
            if (productsName === "ring" || productsName === "cuff" || productsName === "cufflinks" || productsName === "tiebar") {
            }
            else {
                canvasTmp.obj.dispose();
                canvasTmp.obj = null;
            }
            //threeRef.current = null;
        };
    }
    useEffect(() => {
        if (loadingDone >= 100 && productData) {
            setTimeout(() => {
                const showTeach = true;// localStorage.getItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid) ? false : true;
                if (productsName === "ring") {
                    save();
                }
                let _pid = pid;
                if (["r1", "r2", "r3"].includes(pid))
                    _pid = "rings";
                const showTeachModal = localStorage.getItemByLang(i18n.language, "autoShowTechModal", _pid);
                if (!showTeachModal)
                    openTeachModal(showTeach);
                else {
                    if (showTeach)
                        customEventManager.dispatch("startTeach");
                }
                isLoad.current = true;
                set_isLoading(false);
            }, 1000);
        }
    }, [loadingDone, productData]);
    useEffect(() => {
        if (color) {
            customEventManager.dispatch("ch_color", color);
        }
    }, [color]);


    useEffect(() => {
        if (buttonsData.length > 0) {
            const target = buttonsData.filter(b => (b.selected == true))[0];
            console.log("tags", target);
            customEventManager.dispatch("threejs", { action: "changeToggle", arg: target?.name });
            customEventManager.dispatch("teach", { action: "changeToggle_" + target?.name });
            const tags = target.tags.map((tagName) => {
                return { toggleOption: target.id, name: tagName, selected: false }
            })
            setTags(tags);
            tags[0].selected = true;
            showBeads(target.id, tags[0].name);
        }
    }, [buttonsData])

    useEffect(() => {
        if (selBall.length) {
            if (productsName === "ring") {
                if (selBall[0] && selBall[1] && selBall[2] && selBall[3]) {
                    console.log("====selBall", selBall);
                    customEventManager.dispatch("changePart", { selKarat: selBall[0].arg, selCenter: selBall[1].arg, selMouting: selBall[2].arg, selMetalColor: selBall[3].arg, selKaratId: selBall[0].id, selCenterId: selBall[1].id, selMoutingId: selBall[2].id, selMetalColorId: selBall[3].id });
                }
            }
            else if (productsName === "cufflinks" || productsName === "tiebar" || productsName === "cuff") {
                localStorage.setItem('ball', JSON.stringify(selBall));
            }
            if (isLoad.current)
                save();
        }
    }, [selBall])

    function switchDragBallArg1(type) {
        dragBall.arg1 = type;
        /* switch(toggleOption)
        {
            case "Other Beads":
            case "Beads":
                dragBall.arg1=toggleOption;
                break;
            case "Middle Beads":
            case "Pendants":
                    dragBall.arg1=1;
                    break;
        } */
    }
    function showBeads(toggleOption, tag) {
        console.log("productData", productData, toggleOption, tag);
        for (var i = 0; i < productData.toggleOptions.length; i++) {
            if (productData.toggleOptions[i].name == toggleOption) {
                switchDragBallArg1(productData.toggleOptions[i].type);
                for (var j = 0; j < productData.toggleOptions[i].tags.length; j++) {
                    if (productData.toggleOptions[i].tags[j].name == tag) {
                        if (productsName === "ring") {
                            if (toggleOption === "DIMOND" && selBall[1]) {
                                const _beads = productData.toggleOptions[i].tags[j].beads.filter(v => v.limit.indexOf(selBall[1].arg) >= 0);
                                setBall(_beads);
                            }
                            else if (toggleOption === "CENTER SETTING" && selBall[0]) {
                                const _beads = productData.toggleOptions[i].tags[j].beads.filter(v => v.limit.indexOf(selBall[0].arg) >= 0);
                                setBall(_beads);
                            }
                            else
                                setBall(productData.toggleOptions[i].tags[j].beads);
                        }
                        else
                            setBall(productData.toggleOptions[i].tags[j].beads);
                        break;
                    }
                }
            }
        }
    }

    const toPreview = useCallback(() => {
        if (productsName === 'ring')
            document.location.href = `#/product/Ring`;
        else if (['cuff', 'cufflinks', 'tiebar'].includes(productsName)) {
            const ballStr = localStorage.getItem('ball');
            if (ballStr) {
                const ballData = JSON.parse(ballStr);
                document.location.href = `#/preview/${productsName}/${pid}`;
            }
        } else {
            const ballStr = localStorage.getItem('ball');
            if (ballStr) {
                const ballData = JSON.parse(ballStr);
                ballData.forEach(ball => {
                    if (ball.laser)
                        ball.laserData = laserData.current[ball.pos];
                });
                localStorage.setItem('ball', JSON.stringify(ballData));
                document.location.href = `#/preview/${productsName}/${pid}/${arg}/${bead}`;
            }
        }
    }, []);

    function calPrice() {
        const sum = selBall.reduce((accumulator, object) => {
            if (object.price)
                return accumulator + object.price;
            else
                return accumulator;
        }, 0);
        if (productsName === "ring")
            return sum;
        else if (productsName === "cuff")
            return sum;
        else if (productsName === "cufflinks")
            return sum;
        else if (productsName === "tiebar")
            return sum;
        else {
            if (productData)
                return sum + productData.basePrice;
            else
                return sum;
        }

    }

    function changeBtn(id) {
        console.log("btnEnable", btnEnable)
        if (btnEnable) {
            setButtons(prevData => {
                return prevData.map(item => {
                    item.selected = (id == item.id);
                    return item;
                })
            });
        }
    }

    function changeTag(target) {
        customEventManager.dispatch("threejs", { action: "changeTag", arg: target.name });
        customEventManager.dispatch("teach", { action: "changeTag_" + target.name });
        setTags(prevData => {
            return prevData.map(item => {
                item.selected = (target.name == item.name);
                return item;
            })
        });
        showBeads(target.toggleOption, target.name);
    }

    function save() {
        console.log("+++++++++++++++++++++",pid,selBall);
        localStorage.setItemByLang(i18n.language, "tempSave", pid, { product: { productsName: productsName, pid: pid, arg: arg, bead: bead }, selBall: selBall });
    }
    function getLoad(_productData) {
        console.log("_productData", productsName, pid)
        const tempSave = localStorage.getItemByLang(i18n.language, "tempSave", pid);
        if (tempSave)
            return tempSave.selBall;
        else {
            if (productsName === "bracelets" || productsName === "necklaces")
                return [];
            else {
                const defaultBalls = _productData.toggleOptions.map(v => v.tags[0].beads[0]);
                return defaultBalls;
            }
        }
    }

    function openTeachModal(showTeach) {
        setModalType(prev => [...prev, {
            cls: "TeachModal", arg: {
                productsName: productsName, pid: pid, cb: () => {
                    if (showTeach)
                        customEventManager.dispatch("startTeach");
                }
            }
        }])
    }
    function afterOpenModal() {
    }

    function closeLaserModal(pos, res) {
        customEventManager.dispatch("teach", { action: "laserKeyIn" });
        laserData.current[pos] = res;
        set_laserModalData(null);
    }

    return (
        <div className={style.app}>
            <div ref={threeRef} className={style.canvas} >
                {(productsName === "ring" || productsName === "cuff" || productsName === "cufflinks" || productsName === "tiebar") &&
                    <DivH Hpx={getThreeCanvasHeight()}>
                        {preloadBalls.current.length &&
                            <ReactCanvas productsName={productsName} set_isLoading={set_isLoading} setLoadingDone={setLoadingDone} previewBtnRef={previewBtnRef} toPreview={toPreview} pid={pid} preloadBalls={preloadBalls} />
                        }
                        {/* <HexColorPicker style={{position:"absolute",top:"0px"}} className="picker" color={color} onChange={setColor} /> */}
                    </DivH>

                }
                <div className={style.bottomBox}>
                    {teachTextA != "" &&
                        <div className={style.teachInfo}>{teachTextA}</div>
                    }
                    <Buttons style={style} buttonsData={buttonsData} changeBtn={changeBtn}></Buttons>
                </div>
                <div className={style.nextDiv}>
                    <button disabled={isLoading} id="previewBtn" className={style.previewBtn} ref={previewBtnRef} >{productsName === "ring" ? `${t('ringAddHomeTry')}(${pid.substr(1, 1)}/3)` : (["cuff", "cufflinks", "tiebar"].includes(productsName)) ? t('next') : t('preview')}</button>
                    {productsName !== "ring" &&
                        <div className={style.previewPrice}>{
                            calPrice()
                        } $</div>
                    }
                </div>
                {isMobile === false ?
                    <div className={style.topMenu} >
                        <Link to={'/'}><FontAwesomeIcon className={style.close} icon={faClose} /></Link>
                        <div className={style.help} onClick={openTeachModal} >{t('INSTRUCTION')}
                            <TeachManager isMobile={false} productsName={productsName} pid={pid} canvasTmp={canvasTmp} setBtnEnable={setBtnEnable} setTeachTextA={setTeachTextA} changeBtn={changeBtn} set_laserModalData={set_laserModalData} />
                        </div>
                    </div>
                    :
                    <>
                        <div className={style.topMenu} >
                            <div className={style.help} onClick={e => {
                                setModalType(prev => [...prev, {
                                    cls: "PhoneOptionModal", arg: {
                                        btns: [{
                                            "label": t('backToTop'), cb: () => {
                                                document.location.href = '#';
                                            }
                                        },
                                        {
                                            "label": t('3dControl_instruction'), cb: () => {
                                                openTeachModal(false);
                                            }
                                        },
                                        {
                                            "label": t('onlineSupport'), cb: () => {
                                                var newWindow = window.open(i18n.language === 'en' ? 'http://m.me/1237150356322077' : 'http://m.me/120341367683921', '_blank');
                                                if (newWindow) {
                                                    newWindow.focus();
                                                }
                                            }
                                        }
                                        ],
                                    }
                                }])
                            }} >
                                <img src="./images/option.png" />
                            </div>
                            <TeachManager isMobile={isMobile} productsName={productsName} pid={pid} canvasTmp={canvasTmp} setBtnEnable={setBtnEnable} setTeachTextA={setTeachTextA} changeBtn={changeBtn} set_laserModalData={set_laserModalData} />
                        </div>
                    </>
                }
                {/* <MessageBtn styleIndex={"editor"} /> */}
            </div>
            {loadingDone >= 100 &&
                <div className={style.bottom}>
                    <div className={style.menu_tag}>
                        <div className={style.tags}>
                            {//<div className={style.tag}>CLASSIC</div>
                                tagData.map(item => {
                                    if (item.name)
                                        return <div key={v4()} onClick={() => { changeTag(item) }} className={item.selected ? style.tagSelect : style.tag}>{item.name}</div>
                                })
                            }
                        </div>
                        <div className={style.menu}>
                            {
                                ballArr.map(item => {
                                    function dragstart_handler(ev) {
                                        dragBall.nid = item.id;
                                        dragBall.laser = item.laser;
                                    }
                                    function showModelPath() {
                                        console.log("productsName", productsName, tagData[0].toggleOption)
                                        if (productsName === "ring") {
                                            switch (tagData[0].toggleOption) {
                                                case "DIAMOND":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[0] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("teach", { action: "selRing" });
                                                    break;
                                                case "CENTER SETTING":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[1] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("teach", { action: "selCenter" });
                                                    break;
                                                case "MOUTING STYLE":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[2] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("teach", { action: "selMouting" });
                                                    break;
                                                case "METAL COLORS":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[3] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("teach", { action: "selRingColor" });
                                                    break;
                                            }

                                        }
                                        else if (productsName === "cuff") {
                                            switch (tagData[0].toggleOption) {
                                                case "STYLE":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[0] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("changePart", item);
                                                    customEventManager.dispatch("teach", { action: "selStyle" });
                                                    break;
                                                case "WRIST SIZE":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[1] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("teach", { action: "selSize" });
                                                    break;
                                            }
                                        }
                                        else if (productsName === "cufflinks" || productsName === "tiebar") {
                                            switch (tagData[0].toggleOption) {
                                                case "STYLE":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[0] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("changePart", item);
                                                    customEventManager.dispatch("teach", { action: "selStyle" });
                                                    break;
                                                case "GEMSTONE":
                                                    setSelBall(prev => {
                                                        const arr = [...prev];
                                                        arr[1] = item;
                                                        return arr;
                                                    });
                                                    customEventManager.dispatch("changePart2", item);
                                                    customEventManager.dispatch("teach", { action: "selGem" });
                                                    break;
                                            }
                                        }
                                        if (dragBall.arg1 == 5) {
                                            console.log("dragBall", dragBall, item)
                                            canvasTmp.obj.changeBaseColor(item);
                                        }
                                        if (item.id == 116) {
                                            canvasTmp.obj.offPendant(item);
                                        }
                                    }
                                    return <div onClick={showModelPath} key={item.id} className={`${style.ballDiv} ${(productsName === "ring" || productsName === "cuff" || productsName === "cufflinks" || productsName === "tiebar") && selBall.find(v => v.id === item.id) ? style.hovered : ''}`}>
                                        {/* {(item.image !== "" && item.image) & */}
                                        <Ball key={item.id} draggable="true" onDragStart={dragstart_handler} img={item.image}></Ball>
                                        <p className={style.name}>{["WRIST SIZE"].includes(tagData[0].toggleOption) ? "" : item.name}</p>
                                        {["ring", "cuff"].includes(productsName) === false ?
                                            <p>${item.price}</p>
                                            :
                                            <p></p>
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {loadingDone < 100 &&
                <div className={style.loadingBar}>
                    <div>
                        <img src="./images/logo_icon.png" />
                        <p>{t('LOADING')}... {loadingDone}%</p>
                    </div>
                    <div><LoadingBar process={loadingDone} /></div>
                </div>
            }
            <Modal
                isOpen={laserModalData !== null}
                onAfterOpen={afterOpenModal}
                style={isMobile ? customStyles2 : customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal"
            >
                <LaserCustom img={laserModalData?.img} laserType={productsName} onConfirm={res => { closeLaserModal(laserModalData?.pos, res) }} confirmStr="CONFIRM" textMaxLen={3} />
            </Modal>

        </div>
    );
}

export default Editor;