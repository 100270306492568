import config from '../config';
import Cookies from 'universal-cookie';
import md5 from "js-md5";
const cookies = new Cookies(null,{ path: '/' });
export const baseUrl = config.serverUrl;

export const fetchGet = (url, query, lang = "", cacheMins = 0) => new Promise(async (resolve, reject) => {
    url = url + `?lang=${lang}&` + new URLSearchParams(query);
    console.log("---------url",url)
    let _cacheRet=cookies.get(md5(url));
    if(_cacheRet&&cacheMins!==0)
    return resolve(_cacheRet);
    console.log("get:", url, md5(url));
    fetch(baseUrl + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((ret) => {
            if (cacheMins !== 0)
            {
                console.log("xxxxxxxx",cacheMins,md5(url))
                cookies.set(md5(url), ret, { path: '/', maxAge: cacheMins * 60 });
            }
            resolve(ret)
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
});

export const fetchPost = (url, body) => new Promise(async (resolve, reject) => {
    const header = {
        'accept': 'application/json',
        'Content-Type': 'application/json',
    };
    //console.log(baseUrl + url, body, JSON.stringify(body))
    fetch(baseUrl + url, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .then((ret) => {
            resolve(ret)
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
});
export const fetchPostForm = (url, formData) => new Promise(async (resolve, reject) => {
    fetch(baseUrl + url, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json())
        .then((ret) => {
            resolve(ret)
        })
        .catch((error) => {
            console.error('Error:', error);
            reject(error);
        });
});

// 格式化日期为字符串（YYYY/MM/DD）
export const formatDate = (date) => {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return year + '/' + month + '/' + day;
}

export const isValidPhoneNumber = (phoneNumber) => {
    // 匹配美国和台湾的手机号码和市话号码，允许包含国码
    const regexUS = /^(\+?1?[0-9]{10}|\+[0-9]{1,4}-[0-9]{1,10})$/; // 匹配美国手机号码，可包含国家码 +1
    const regexTW = /^\+?886[0-9]{8,10}$/;        // 匹配台湾手机号码和市话号码，可包含国家码 +886
    // 使用正则表达式进行验证
    return regexUS.test(phoneNumber) || regexTW.test(phoneNumber);
}
export const isValidEmail = (email) => {
    // 使用正则表达式检查电子邮件地址格式
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // 返回正则表达式的测试结果
    return emailRegex.test(email);
}
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const createCounterFn = (times, fn) => {
    let counter = 0;
    return () => {
        counter++;
        fn(counter, times);
    };
};
//  const stateMachine = createStateMachine("ready,rotate,move,reset,middle,other,laser");
export const createStateMachine = (validTransitions) => {
    let currentIndex = 0;
    return function checkStateTransition(nextState) {
        if (currentIndex >= validTransitions.length)
            return false;
        let validTransition = validTransitions[currentIndex];
        if (validTransition.endsWith(":"))
            validTransition = validTransition.slice(0, -1)
        let checkActions = [validTransition];
        if (currentIndex > 0) {
            let lastValidTransition = validTransitions[currentIndex - 1];
            if (lastValidTransition.endsWith(":")) {
                checkActions.push(lastValidTransition.slice(0, -1));
            }
        }
        if (checkActions.includes(nextState)) {
            if (validTransition === nextState)
                currentIndex++;
            return true;
        } else {
            return false;
        }
    };
}

export const replaceColorWithTransparent = (canvas, targetColor) => {
    const context = canvas.getContext('2d');

    // 获取整个Canvas的像素数据
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const pixelData = imageData.data;

    // 将目标颜色替换为透明
    for (let i = 0; i < pixelData.length; i += 4) {
        const red = pixelData[i];
        const green = pixelData[i + 1];
        const blue = pixelData[i + 2];
        const alpha = pixelData[i + 3];

        // 判断当前像素的颜色是否与目标颜色匹配
        if (red === targetColor.red && green === targetColor.green && blue === targetColor.blue) {
            // 设置透明度为0
            pixelData[i + 3] = 0;
        }
    }

    // 将修改后的像素数据重新绘制到Canvas上
    context.putImageData(imageData, 0, 0);
}
export const cropImageFromCanvas = (ctx, color, isTransparent) => {
    var canvas = ctx.canvas,
        w = canvas.width, h = canvas.height,
        pix = { x: [], y: [] },
        imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
        x, y, index;

    for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
            index = (y * w + x) * 4;
            if (!(imageData.data[index] === color[0] && imageData.data[index + 1] === color[1] && imageData.data[index + 2] === color[2] && imageData.data[index + 3] === color[3])) {
                pix.x.push(x);
                pix.y.push(y);
            }
        }
    }
    pix.x.sort(function (a, b) { return a - b });
    pix.y.sort(function (a, b) { return a - b });
    var n = pix.x.length - 1;

    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];
    var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
    if (isTransparent) {
        for (y = 0; y < h; y++) {
            for (x = 0; x < w; x++) {
                index = (y * w + x) * 4;
                if ((cut.data[index] === color[0] && cut.data[index + 1] === color[1] && cut.data[index + 2] === color[2] && cut.data[index + 3] === color[3])) {
                    cut.data[index + 3] = 0;
                }
            }
        }
    }


    canvas.width = w;
    canvas.height = h;
    console.log("w,h", w, h)
    ctx.putImageData(cut, 0, 0);
}

export const drawCurevd = (id, canvasDiv, inputText, fontfamily, windowWidth, fontSize, fontColor, points) => {
    startIt();
    var canvas, ctx, curve, points, Ribbon, x1, x2, p, j, dx, dx2, xDist, ww, z, letterPadding, totalPadding, a, b, c, w, totalLength, cDist;
    function startIt() {
        let scaleX = (windowWidth * 0.84) / 600;
        if (scaleX > 1)
            scaleX = 1;
        canvasDiv.innerHTML = '<canvas id=' + id + ' width="600" height="450" style="transform: scale(' + scaleX + ');"></canvas>'; //for IE
        canvas = document.getElementById(id);
        ctx = canvas.getContext('2d');
        ctx.fillStyle = fontColor;
        ctx.font = `${fontSize}px ${fontfamily}`;
        curve = document.getElementById('curve');
        changeCurve();
    }

    function changeCurve() {
        if (points.length == 8)
            drawStack();

    }

    function drawStack() {
        Ribbon = {
            maxChar: 50, startX: points[0], startY: points[1],
            control1X: points[2], control1Y: points[3],
            control2X: points[4], control2Y: points[5],
            endX: points[6], endY: points[7]
        };

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        /*  ctx.beginPath();
 
         ctx.moveTo(Ribbon.startX, Ribbon.startY);
         ctx.bezierCurveTo(Ribbon.control1X, Ribbon.control1Y,
             Ribbon.control2X, Ribbon.control2Y,
             Ribbon.endX, Ribbon.endY);
 
         ctx.stroke();
         ctx.restore(); */

        FillRibbon(inputText, Ribbon);
    }

    function FillRibbon(text, Ribbon) {

        var textCurve = [];
        var ribbon = text.substring(0, Ribbon.maxChar);
        var curveSample = 1000;


        xDist = 0;
        var i = 0;
        for (i = 0; i < curveSample; i++) {
            a = new bezier2(i / curveSample, Ribbon.startX, Ribbon.startY, Ribbon.control1X, Ribbon.control1Y, Ribbon.control2X, Ribbon.control2Y, Ribbon.endX, Ribbon.endY);
            b = new bezier2((i + 1) / curveSample, Ribbon.startX, Ribbon.startY, Ribbon.control1X, Ribbon.control1Y, Ribbon.control2X, Ribbon.control2Y, Ribbon.endX, Ribbon.endY);
            c = new bezier(a, b);
            textCurve.push({ bezier: a, curve: c.curve });
        }

        letterPadding = ctx.measureText(" ").width / 4;
        w = ribbon.length;
        ww = Math.round(ctx.measureText(ribbon).width);


        totalPadding = (w - 1) * letterPadding;
        totalLength = ww + totalPadding;
        p = 0;

        cDist = textCurve[curveSample - 1].curve.cDist;

        z = (cDist / 2) - (totalLength / 2);

        for (i = 0; i < curveSample; i++) {
            if (textCurve[i].curve.cDist >= z) {
                p = i;
                break;
            }
        }

        for (i = 0; i < w; i++) {
            ctx.save();
            ctx.translate(textCurve[p].bezier.point.x, textCurve[p].bezier.point.y);
            ctx.rotate(textCurve[p].curve.rad);
            ctx.fillText(ribbon[i], 0, 0);
            ctx.restore();

            x1 = ctx.measureText(ribbon[i]).width + letterPadding;
            x2 = 0;
            for (j = p; j < curveSample; j++) {
                x2 = x2 + textCurve[j].curve.dist;
                if (x2 >= x1) {
                    p = j;
                    break;
                }
            }




        }
    } //end FillRibon

    function bezier(b1, b2) {
        //Final stage which takes p, p+1 and calculates the rotation, distance on the path and accumulates the total distance
        this.rad = Math.atan(b1.point.mY / b1.point.mX);
        this.b2 = b2;
        this.b1 = b1;
        dx = (b2.x - b1.x);
        dx2 = (b2.x - b1.x) * (b2.x - b1.x);
        this.dist = Math.sqrt(((b2.x - b1.x) * (b2.x - b1.x)) + ((b2.y - b1.y) * (b2.y - b1.y)));
        xDist = xDist + this.dist;
        this.curve = { rad: this.rad, dist: this.dist, cDist: xDist };
    }

    function bezierT(t, startX, startY, control1X, control1Y, control2X, control2Y, endX, endY) {
        //calculates the tangent line to a point in the curve; later used to calculate the degrees of rotation at this point.
        this.mx = (3 * (1 - t) * (1 - t) * (control1X - startX)) + ((6 * (1 - t) * t) * (control2X - control1X)) + (3 * t * t * (endX - control2X));
        this.my = (3 * (1 - t) * (1 - t) * (control1Y - startY)) + ((6 * (1 - t) * t) * (control2Y - control1Y)) + (3 * t * t * (endY - control2Y));
    }

    function bezier2(t, startX, startY, control1X, control1Y, control2X, control2Y, endX, endY) {
        //Quadratic bezier curve plotter
        this.Bezier1 = new bezier1(t, startX, startY, control1X, control1Y, control2X, control2Y);
        this.Bezier2 = new bezier1(t, control1X, control1Y, control2X, control2Y, endX, endY);
        this.x = ((1 - t) * this.Bezier1.x) + (t * this.Bezier2.x);
        this.y = ((1 - t) * this.Bezier1.y) + (t * this.Bezier2.y);
        this.slope = new bezierT(t, startX, startY, control1X, control1Y, control2X, control2Y, endX, endY);

        this.point = { t: t, x: this.x, y: this.y, mX: this.slope.mx, mY: this.slope.my };
    }
    function bezier1(t, startX, startY, control1X, control1Y, control2X, control2Y) {
        //linear bezier curve plotter; used recursivly in the quadratic bezier curve calculation
        this.x = ((1 - t) * (1 - t) * startX) + (2 * (1 - t) * t * control1X) + (t * t * control2X);
        this.y = ((1 - t) * (1 - t) * startY) + (2 * (1 - t) * t * control1Y) + (t * t * control2Y);

    }
}