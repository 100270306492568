import * as THREE from 'three'
import { useState, useRef, useEffect, memo } from 'react'
import { useGLTF, MeshRefractionMaterial, AccumulativeShadows, RandomizedLight, Html, Environment, Center, PresentationControls, ArcballControls } from '@react-three/drei'
import { CustomEventManager } from '../../global/CustomEventManager'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { mergeBufferGeometries } from 'three-stdlib';

const loader = new GLTFLoader();
const customEventManager = new CustomEventManager();
const Ring2 = ({ map, ...props }) => {
    const [node_head, set_node_head] = useState([]);
    const [node_diamond, set_node_diamond] = useState([]);
    const [node_body, set_node_body] = useState([]);
    const [node_all, set_node_all] = useState(null);

    const loaderPartModel = async (data, type, setFn) => {
        const path = `./models/4_test/test_2.gltf`;
        loader.load(path, gltf => {
            function collectMeshes(node, resultArray) {
                if (node.type === 'Mesh') {
                  // 如果是 Mesh，將其加入結果 array
                  resultArray.push(node);
                } else if (node.type === 'Group' && node.children) {
                  // 如果是 Group 且有 children，遞迴處理每個 child
                  for (const child of node.children) {
                    collectMeshes(child, resultArray);
                  }
                }
              }
              
              // 創建一個空的 array 來存放 Mesh
              const allMeshes = [];
              
              // 使用遞迴函式收集 Mesh
              for (const child of gltf.scene.children) {
                collectMeshes(child, allMeshes);
              }
              
              // 現在 allMeshes 中包含了所有的 Mesh
              console.log(allMeshes);
              setFn(allMeshes);
        });
    }
    useEffect(() => {
        const preSetting = { selKarat: '05', selCenter: '04', selMouting: '03', selMetalColor: '14KGold' };
        loaderPartModel(preSetting, "diamond", set_node_diamond);
        
        customEventManager.addEventListener("changePart", changePart);
        return () => {
            customEventManager.removeEventListener("changePart", changePart);
        };
    }, []);
    useEffect(() => {
        if (node_diamond.length > 0) {
            const arrDiamond = node_diamond.filter(v => v.name.indexOf("diamond")!==-1)
            console.log("arrDiamond", arrDiamond)
            const arrGeometry= arrDiamond.map(v=>v.geometry);
            console.log("arrGeometry", arrGeometry)
            if (arrGeometry) {
                const mergedGeometry = mergeBufferGeometries(arrGeometry,false);
                set_node_all(mergedGeometry)
            }
            else
                set_node_all(null)
            //
        }
    }, [node_diamond]);
    const changePart = (data) => {
        loaderPartModel(data, "diamond", set_node_diamond);
        loaderPartModel(data, "head", set_node_head);
        loaderPartModel(data, "body", set_node_body);
    }
    return (
        <group {...props} dispose={null}>
            {node_all &&
                <mesh geometry={node_all} >
                    <MeshRefractionMaterial envMap={map} aberrationStrength={0.005} toneMapped={true} />
                </mesh>
            }
            {node_diamond.map(v => {
                if (v.name.indexOf("diamond")===-1)
                    return <mesh key={v.name} geometry={v.geometry} material={v.material}>
                    </mesh>
            })} 
            {node_head.map(v => {
                return <mesh key={v.name} geometry={v.geometry} material={v.material}>
                </mesh>
            })}
        </group>
    )
}

export default memo(Ring2);