import * as THREE from 'three'
import { useState, useRef, useEffect, memo } from 'react'
import { useGLTF, MeshRefractionMaterial, AccumulativeShadows, RandomizedLight, Html, Environment, Center, PresentationControls, ArcballControls } from '@react-three/drei'
import { CustomEventManager } from '../../global/CustomEventManager'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { mergeBufferGeometries } from 'three-stdlib';
import { wait } from '../../../comm/comm';

const loader = new GLTFLoader();
const customEventManager = new CustomEventManager();
const Ring = ({ map,set_isLoading, setLoadingDone, ringRef, preloadBalls, ...props }) => {
    const [node_head, set_node_head] = useState([]);
    const [node_diamond, set_node_diamond] = useState([]);
    const [node_body, set_node_body] = useState([]);
    const [node_all, set_node_all] = useState(null);
    const [showMap, set_showMap] = useState(true);
    const [color, setColor] = useState('white');
    const [metalColor, setMetalColor] = useState('white');
    const beadsRef = useRef([]);
    const loaderPartModel = (data, type, setFn) => {
        return new Promise(async (resolve, reject) => {
            let style = "01";
            if (type === "diamond" || type === "head")
                style = data.selCenter;
            else if (type === "body")
                style = data.selMouting;
            const path = `./models/4_${style}_ring_${data.selMetalColor}/4_${style}_ring_${data.selMetalColor}.gltf`;
            const _nodes = [];
            try {
                loader.load(path, gltf => {
                    if (type === "diamond") {
                        const model = gltf.scene.children.find(v => v.name === `diamond_${data.selKarat}`);
                        if (model)
                            _nodes.push(model);
                    }
                    else if (type === "head") {
                        const model = gltf.scene.children.find(v => v.name === `head_${data.selKarat}`);
                        if (model)
                            _nodes.push(model);
                    }
                    else if (type === "body") {
                        const model = gltf.scene.children.find(v => v.name === `body`);
                        if (model)
                            _nodes.push(model);
                        const model2 = gltf.scene.children.find(v => v.name === `diamond`);
                        if (model2)
                            _nodes.push(model2);
                    }
                    setFn(_nodes);
                    resolve(0);
                }, process => {
                    //console.log("...process", process)
                }, error => { resolve(0); });
            } catch (error) {
                resolve(0);
            }
        });
    }
    useEffect(() => {
        const beadsStr = localStorage.getItem("beads");
        if (beadsStr) {
            beadsRef.current = JSON.parse(beadsStr);
            console.log("beadsRef.current", beadsRef.current)
        }
        console.log("preloadBalls", preloadBalls)
        changePart({
            selKarat: preloadBalls.current[0].arg,
            selCenter: preloadBalls.current[1].arg,
            selMouting: preloadBalls.current[2].arg,
            selMetalColor: preloadBalls.current[3].arg,
            selKaratId: preloadBalls.current[0].id,
            selCenterId: preloadBalls.current[1].id,
            selMoutingId: preloadBalls.current[2].id,
            selMetalColorId: preloadBalls.current[3].id
        }, (counter, times) => {
            setLoadingDone(parseInt(counter / times * 100));
        });
        customEventManager.addEventListener("changePart", changePart);
        customEventManager.addEventListener("xx", upp);
        customEventManager.addEventListener("ch_color", ch_color);
        return () => {
            customEventManager.removeEventListener("changePart", changePart);
            customEventManager.removeEventListener("xx", upp);
            customEventManager.removeEventListener("ch_color", ch_color);
        };
    }, []);
    const ch_color = async (color) => {
        console.log("color", color)
        //setColor(color);
    }
    useEffect(() => {
        if (node_body.length > 0 && node_diamond.length > 0) {
            const geometries = [
                ...node_body.map(v => v.geometry),
                ...node_diamond.map(v => v.geometry),
            ];
            const bodyDiamond = node_body.find(v => v.name === "diamond")
            console.log("mergedGeometry", bodyDiamond)
            if (bodyDiamond) {
                const mergedGeometry = mergeBufferGeometries([node_diamond[0].geometry, bodyDiamond.geometry], false);
                set_node_all(mergedGeometry)
            }
            else
                set_node_all(node_diamond[0].geometry)
            //
        }
    }, [node_body, node_diamond]);

    const upp = async (data, doneCb) => {
        set_showMap(false);
    }
    useEffect(() => {
        if (showMap === false) {
            set_showMap(true);
        }
    }, [showMap]);
    const changePart = async (data, doneCb) => {
        console.log("change.......",data)
        set_isLoading(true);
        await loaderPartModel(data, "diamond", set_node_diamond);
        if (doneCb)
            doneCb(1, 3);
        await loaderPartModel(data, "head", set_node_head);
        if (doneCb)
            doneCb(2, 3);
        await loaderPartModel(data, "body", set_node_body);
        if (doneCb)
            doneCb(3, 3);
        set_showMap(false);
        //get color
        const obj = beadsRef.current.find(v => v.id === data.selMetalColorId);
        if (obj?.effects) {
            if (obj.effects?.color) {
                console.log(obj.effects.color)
                setMetalColor(`#${obj.effects.color}`);
            }
            else if (obj.effects?.bodyColor) {
                console.log(obj.effects.bodyColor)
                setMetalColor(`#${obj.effects.bodyColor}`);
            }
        }
        else
            setMetalColor('white');
            set_isLoading(false);
    }
    return (
        <group {...props} dispose={null} ref={ringRef}>
            {node_all &&
                <mesh geometry={node_all} material-color={color}>
                    {showMap &&
                        <MeshRefractionMaterial envMap={map} aberrationStrength={0.005} toneMapped={false} />
                    }
                </mesh>
            }
            {node_body.map(v => {
                if (v.name !== "diamond")
                    return <mesh key={v.name} geometry={v.geometry} material={v.material} material-color={metalColor}>
                    </mesh>
            })}
            {node_head.map(v => {
                return <mesh key={v.name} geometry={v.geometry} material={v.material} material-color={metalColor}>
                </mesh>
            })}
        </group>
    )
}

export default memo(Ring);