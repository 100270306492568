import { useState, useEffect, useRef, Fragment } from "react";
import style from "./index.module.scss";
import allStyle from "../../global/all.module.scss";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
const Page1 = ({ set_step, uploadFiles, set_uploadFiles, ideas, set_ideas }) => {
    const { t, i18n } = useTranslation();
    const ideasRef = useRef();
    const MAXSIZE = 10000000;
    useEffect(() => {

    }, [ideas, uploadFiles])

    const handleImageSelect = (event, index) => {
        if (event.target.files.length) {
            const file = event.target.files[0];
            console.log(file)
            if (file.size > MAXSIZE)
                return alert(t('customMbOptionWaring'));
            if (!["image/png", "image/jpeg", "application/pdf"].includes(file.type)) {
                return alert(t('customMbOptionWaring'));
            }
            set_uploadFiles(prev => {
                const newUploadFiles = [...prev];
                newUploadFiles[index] = file;
                return newUploadFiles;
            })
        }
    };
    const handleIdeasChange = (event) => {
        // 更新 ideas 的值
        set_ideas(event.target.value);
    };
    const nextStep = () => {
        if (ideas === "") {
            ideasRef.current.focus();
        }
        else
            set_step(prev => prev + 1);
    };
    return (
        <div className={style.page1View}>
            <h1>{t(`customTitle_1`)}</h1>
            <h2>{t(`customSubTitle_1`)}</h2>
            <div className={style.inputArea}>
                <div className={style.upload}>
                    <div className={style.list}>
                        {
                            uploadFiles.map((uploadFile, k) => {
                                return <label key={v4()}>{(uploadFile) ? uploadFile.name : t('uploadFiles')}
                                    <input type="file" onChange={e => { handleImageSelect(e, k) }} accept="image/*, application/pdf" />
                                </label>
                            })
                        }

                    </div>
                    <span>({t('uploadFileSize')})</span>
                </div>
                <div className={style.ideas}>
                    <textarea ref={ideasRef} placeholder={t('shareIdeas')} value={ideas}
                        onChange={handleIdeasChange}
                    />
                    <div className={style.btns}>
                        <button className={allStyle.btnWhite} onClick={e => {
                            document.location.href = `#/`;
                        }}>{t('back')}</button>
                        <button className={allStyle.btnBlack} onClick={e => {
                            nextStep();
                        }}>{t('next')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page1;