import { useState, useEffect, useRef, Fragment } from "react";
import style from "./index.module.scss";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import allStyle from "../../global/all.module.scss";
import { useMediaQuery } from 'react-responsive'
const theme = createTheme({
    palette: {
        black: {
            main: '#000000',
            light: '#333333',
            contrastText: '#fff',
        },
    },
});
const Page2 = ({ set_step, min, set_min, max, set_max, MAX_BUDGET, sliderArr, set_sliderArr, bodgetNotSure, set_bodgetNotSure }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const handleChange = (event, newValue) => {
        set_sliderArr(newValue);
    };
    useEffect(() => {
        if (isMobile) {
            set_min(Math.floor((100 - sliderArr[1]) * (MAX_BUDGET / 100)));
            set_max(Math.floor((100 - sliderArr[0]) * (MAX_BUDGET / 100)));
        }
        else {
            set_min(Math.floor(sliderArr[0] * (MAX_BUDGET / 100)));
            set_max(Math.floor(sliderArr[1] * (MAX_BUDGET / 100)));
        }

    }, [sliderArr])
    function valueLabelFormat(value) {
        return `${i18n.language === "en" ? 'USD' : 'NT'}$ ${value * (MAX_BUDGET / 100)}`;
    }
    return (
        <div className={style.page2View}>
            <h1>{t(`customTitle_2`)}</h1>
            <h2>{t(`customSubTitle_2`)}</h2>
            {isMobile ?
                <>
                    <div className={style.ctrl}>
                    <Box sx={{ height: 300 }} className={style.slider}>
                            <ThemeProvider theme={theme}>
                                <Slider
                                    sx={{
                                        '& input[type="range"]': {
                                            WebkitAppearance: 'slider-vertical',
                                        },
                                    }}
                                    orientation="vertical"
                                    aria-label="Temperature"
                                    valueLabelDisplay="auto"

                                    size="small"
                                    getAriaLabel={() => 'Temperature range'}
                                    value={sliderArr}
                                    onChange={handleChange}
                                    color="black"
                                    disabled={bodgetNotSure}
                                    valueLabelFormat={valueLabelFormat}
                                />
                            </ThemeProvider>
                        </Box>
                        <div className={style.budgetRange}>
                            <div className={`${style.limitLabel} ${bodgetNotSure ? style.disabled : ""}`}>
                                <span>{t('min')}</span>
                                <span>{`${i18n.language === "en" ? 'USD' : 'NT'}$ ${min}`}</span>
                            </div>
                            <div className={`${style.line} ${bodgetNotSure ? style.disabled : ""}`}></div>
                            <div className={`${style.limitLabel} ${bodgetNotSure ? style.disabled : ""}`}>
                                <span>{t('max')}</span>
                                <span>{`${i18n.language === "en" ? 'USD' : 'NT'}$ ${max}`}</span>
                            </div>
                        </div>
                    </div>
                    <FormControlLabel className={style.checkbox} control={<Checkbox checked={bodgetNotSure} onClick={e => {
                        set_bodgetNotSure(prev => !prev);
                    }} />} label={t('notSure')} />
                </>
                :
                <>
                    <Box sx={{ width: 724 }} className={style.slider}>
                        <ThemeProvider theme={theme}>
                            <Slider
                                size="small"
                                getAriaLabel={() => 'Temperature range'}
                                value={sliderArr}
                                onChange={handleChange}
                                color="black"
                                disabled={bodgetNotSure}
                                valueLabelDisplay="auto"
                                valueLabelFormat={valueLabelFormat}
                            />
                        </ThemeProvider>
                    </Box>
                    <div className={style.budgetRange}>
                        <div className={`${style.limitLabel} ${bodgetNotSure ? style.disabled : ""}`}>
                            <span>{t('min')}</span>
                            <span>{`${i18n.language === "en" ? 'USD' : 'NT'}$ ${min}`}</span>
                        </div>
                        <div className={`${style.line} ${bodgetNotSure ? style.disabled : ""}`}></div>
                        <div className={`${style.limitLabel} ${bodgetNotSure ? style.disabled : ""}`}>
                            <span>{t('max')}</span>
                            <span>{`${i18n.language === "en" ? 'USD' : 'NT'}$ ${max}`}</span>
                        </div>
                    </div>
                    <FormControlLabel className={style.checkbox} control={<Checkbox checked={bodgetNotSure} onClick={e => {
                        set_bodgetNotSure(prev => !prev);
                    }} />} label={t('notSure')} />
                </>
            }
            <div className={style.btns}>
                <button className={allStyle.btnWhite} onClick={e => {
                    set_step(prev => prev - 1);
                }}>{t('back')}</button>
                <button className={allStyle.btnBlack} onClick={e => {
                    set_step(prev => prev + 1);
                }}>{t('next')}</button>
            </div>
        </div>
    );
}

export default Page2;