import { useMediaQuery } from 'react-responsive';
import { useState, useEffect, useRef, useCallback, useReducer } from "react";
import React, { Component, Fragment } from "react";
import style from "./LaserCoustom.module.scss";
import CustomLaserImg from '../desktop/pages/Editor/components/CustomLaserImg';
import styled from "styled-components";
import Myselect from '../desktop/global/components/Myselect';
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
import { SelectDiv } from "../desktop/global/Styleds";

const LaserCustom = ({ closeBtn = true, img, laserType, onConfirm, confirmStr, textMaxLen, totalPrice }) => {
    const { t, i18n } = useTranslation();
    const [laserTxt, setLaserTxt] = useState("");
    const [laserFont, setLaserFont] = useState("Noto Sans Traditional Chinese");
    const [laserFonts, setLaserFonts] = useState(["Noto Sans Traditional Chinese", "Amatic SC", "Dancing Script", "Nanum Pen Script", "Pinyon Script", "Poiret One", "Gruppo", "DynaPuff", "Reggae One", "ZCOOL QingKe HuangYou", "Dela Gothic One", "Noto Serif Traditional Chinese", "Nanum Myeongjo"]);
    const [laserIcon, setLaserIcon] = useState(["♡", "♢", "♤", "♧", "☼", "✦", "❥", "✪", "✩", "✵", "★", "☆", "☻", "♕", "✞", "†", "✿", "ꕥ", "✻", "❀", "❆", "◉", "⊙", "◯", "Ω", "Σ", "❝", "❞"/* , "☀️" */]);

    useEffect(() => {
        const div = document.createElement('div');
        laserFonts.forEach(font => {
            const span = document.createElement('span');
            span.style.fontFamily = `'${font}', sans-serif`;
            span.textContent = 'z1';
            div.appendChild(span);
        });
        div.style.position="absolute";
        div.style.top="-100%";
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
          };
    }, [])
    return (
        <div className={style.app}>
            <div className={style.modal_info}>
                <CustomLaserImg laserType={laserType} displayImg={img} displayTxt={laserTxt} fontfamily={laserFont} />
            </div>
            <div className={style.modal_detail}>
                <span>1. {t('chooseFont')}</span>
                <SelectDiv width={"100%"}>
                    <Myselect options={laserFonts} defaultSelect={laserFont} setValue={setLaserFont} />
                </SelectDiv>
                <span>2. {t('yourMessage').format(laserTxt.length == 0 ? 0 : laserTxt.match(/./gu).length, textMaxLen)}</span>

                <SelectDiv width={"100%"}>
                    <input type="text" style={{ width: "100%" }} value={laserTxt} maxLength={textMaxLen} onChange={e => { setLaserTxt(e.target.value.replace(/[^0-9A-Za-z'\/\-#$€£¥₩%&@.,+*~?♡♢♤♧☼✦❥✪✩✵★☆☻♕𓁿卍✞†✿ꕥ✻❀❆◉⊙◯𝝅ΩαβΣ❝ ❞]/g, '')) }} />
                </SelectDiv>
                <div className={style.modal_icons}>
                    {
                        laserIcon.map(icon => {
                            return <div key={v4()} onClick={e => { setLaserTxt(prop => (prop + e.target.innerText).substring(0, textMaxLen)) }}>{icon}</div>
                        })
                    }
                </div>
                <span>{t('writeMessageNote')}</span>
                <button onClick={e => {
                    if (laserTxt !== "")
                        onConfirm({ font: laserFont, message: laserTxt })
                }}>{confirmStr}</button>
                {totalPrice &&
                    <span className={style.price}>{t('totalPrice')}: ${totalPrice}</span>
                }
            </div>
            {closeBtn &&
                <img className={style.close} src="./images/close.png" onClick={e => { onConfirm({ font: laserFont, message: "" }) }} />
            }
        </div>
    );
}

export default LaserCustom;