import { useState, useEffect, useRef, Fragment } from "react";
import Header from "../../global/components/Header"
import style from "./index.module.scss";
import { Link, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'
import MessageBtn from "../../global/components/MessageBtn";
import NoticeBar from "../../global/components/NoticeBar";
import styled from "styled-components";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import { fetchGet, fetchPost, fetchPostForm, formatDate } from "../../../comm/comm";
import { useTranslation } from "react-i18next";
const LoadingBar = styled.div`
    height: 100%;
    width: ${props => props.process}%;
    color: #000 !important;
    background: linear-gradient(270deg, #FECC86 0%, #D49234 100%);
    `;
const Custom = ({ cart,setModalType }) => {
    const { t, i18n } = useTranslation();
    let { productsName } = useParams();
    const [step, set_step] = useState(1);
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const [uploadFiles, set_uploadFiles] = useState([null, null, null, null, null]);
    const [ideas, set_ideas] = useState("");
    const MAX_BUDGET = i18n.language === "en" ? 5000 : 150000;
    const [sliderArr, set_sliderArr] = useState([0, 100]);
    const [min, set_min] = useState(0);
    const [max, set_max] = useState(MAX_BUDGET);
    const [bodgetNotSure, set_bodgetNotSure] = useState(false);
    const [dayNotSure, set_dayNotSure] = useState(false);
    const [limitDays, set_limitDays] = useState(30);
    const [specialDay, set_specialDay] = useState(null);

    const [name, set_name] = useState("");
    const [mail, set_mail] = useState("");
    const [prePhone, set_prePhone] = useState("");
    const [phone, set_phone] = useState("");

    useEffect(() => {
        fetchGet(`/designer/special-date-offset`, {}, i18n.language).then(result => {
            set_limitDays(result.days);
        });
    }, [])

    function submitIdeas() {
        const totalSize = uploadFiles.reduce((accumulator, currentFile) => {
            if (currentFile)
                return accumulator + currentFile.size;
            else
                return accumulator;
        }, 0);
        const uploadSpeed = 500;
        const duration = totalSize / uploadSpeed;
        const fnArr={}
        setModalType(prev => [...prev, {
            cls: "Loading", arg: {fnArr:fnArr,processTime:duration}
        }])
        const formDataToSend = new FormData();
        formDataToSend.append('ideas', ideas);
        formDataToSend.append('budget', bodgetNotSure ? null : `${min},${max}`);
        formDataToSend.append('specialDate', dayNotSure ? null : formatDate(specialDay));
        formDataToSend.append('name', name);
        formDataToSend.append('phone', phone);
        formDataToSend.append('email', mail);
        formDataToSend.append('isTest', true);
        formDataToSend.append('files', uploadFiles.filter(v => v));
        fetchPostForm('/designer', formDataToSend).then(res => {
            console.log("res", res);
            fnArr.doCloseFn();
            if (res.statusCode)
                alert(res.message);
            else
            {
                set_step(prev => prev + 1);
            }
        });
    }
    function toPage1(index) {
        if (step === 1)
            return <Page1 set_step={set_step} uploadFiles={uploadFiles} set_uploadFiles={set_uploadFiles}
                ideas={ideas} set_ideas={set_ideas} />
        else if (step === 2)
            return <Page2 set_step={set_step}
                min={min}
                set_min={set_min}
                max={max}
                set_max={set_max}
                MAX_BUDGET={MAX_BUDGET}
                sliderArr={sliderArr}
                set_sliderArr={set_sliderArr}
                bodgetNotSure={bodgetNotSure}
                set_bodgetNotSure={set_bodgetNotSure}
            />
        else if (step === 3)
            return <Page3 set_step={set_step} specialDay={specialDay} set_specialDay={set_specialDay} limitDays={limitDays}
                dayNotSure={dayNotSure}
                set_dayNotSure={set_dayNotSure} />
        else if (step === 4)
            return <Page4 set_step={set_step}
                name={name} set_name={set_name}
                mail={mail} set_mail={set_mail}
                prePhone={prePhone} set_prePhone={set_prePhone}
                phone={phone} set_phone={set_phone}
                submitIdeas={submitIdeas}
            />
        else if (step === 5)
            return <Page5 set_step={set_step} />
    }

    return (
        <div className={style.app}>
            <NoticeBar />
            <Header setModalType={setModalType} cart={cart} />
            {toPage1()}
            <MessageBtn />
        </div>
    );
}

export default Custom;