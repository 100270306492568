import { useState, useEffect, useRef, Fragment } from "react";
import style from "./Header.module.scss";
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from './../../../config';

const Header = ({ setModalType, isEdit = false, cart, save }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const { t, i18n } = useTranslation();
    function saveFn() {
        var lang = i18n.language === "zh" ? "en" : "zh";
        i18n.changeLanguage(lang);

        //save();
    }
    return (
        <div className={style.app}>
            {/* <div className={style.lang} onClick={saveFn}>{`切換語系:${i18n.language}`}</div> */}
            {isMobile ?
                <div className={style.option} onClick={e => {
                    setModalType(prev => [...prev, {
                        cls: "PhoneOptionModal", arg: {
                            btns: [{
                                "label": t('backToTop'), cb: () => {
                                    document.location.href = '#';
                                }
                            },
                            {
                                "label": t('onlineSupport'), cb: () => {
                                    var newWindow = window.open(i18n.language==='en'?'http://m.me/1237150356322077':'http://m.me/120341367683921', '_blank');
                                    if (newWindow) {
                                        newWindow.focus();
                                    }
                                }
                            }
                            ],
                        }
                    }])
                }}><img src="./images/option.png" /></div>
                :
                <div className={style.empty}></div>
            }

            {/* <span className={style.save} onClick={saveFn}>{`切換語系:${i18n.language}`}</span> */}
            <a href="#" className={style.logo}><img src="./images/logom.png" /></a>
            <div className={style.cart} onClick={e => {
                window.open(i18n.language === "en" ?"https://azuro-republic.com/cart":"https://azuro-republic.com.tw/cart", '_blank');
            }}>{t("cart")} </div>
            <div className={style.cartImg} onClick={e => {
                window.open(i18n.language === "en" ?"https://azuro-republic.com/cart":"https://azuro-republic.com.tw/cart", '_blank');
            }}><img src="./images/lock.png" /></div>
        </div>
    );
}

export default Header;