import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
const Main = styled("div")`
  flex-grow: 1;
  height:100%;
`;

const DropDownContainer = styled("div")`
  margin: 0 auto;
  width:100%;
  height:100%;
`;

const DropDownHeader = styled("div")`
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
`;

const DropDownListContainer = styled("div")`
    position: relative;
    left:1px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #D5D5D5;
  box-sizing: border-box;
  height: 20vh;
　overflow-y: scroll;
`;

const ListItem = styled("li")`
  &{
    list-style: none;
    padding:.6rem 14px;
}
&:hover {
    background:#f2f2f2;
}
`;

const ArrowDown = styled("p")`
  padding-left:10px;
`;

const Myselect = ({ options, defaultSelect, setValue }) => {
    const [isOpen, setIsOpenSel] = useState(false);
    const toggling = () => setIsOpenSel(!isOpen);
    const [selectedOption, setSelectedOption] = useState(null);
    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setValue(value);
        setIsOpenSel(false);
        console.log(selectedOption);
    };
    return (
        <Main>
            <DropDownContainer>
                <DropDownHeader onClick={toggling}>
                    <p>{selectedOption || defaultSelect}</p>
                    <ArrowDown>∨</ArrowDown>
                    {/* <img src="./images/v.png" /> */}
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options.map(option => (
                                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                    {option}
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        </Main>
    );
}

export default Myselect;