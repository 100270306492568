import * as THREE from "three";
import v1 from "./util/v1";
import v2 from "./util/v2";
import v3 from "./util/v3";
import v4 from "./util/v4";
import v5 from "./util/v5";
import v6 from "./util/v6";
import { Comm } from "./util/Comm";
import { MyGui } from "./util/MyGui";
import envLight from "./util/envLight";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { ArcballControls } from "./util/ArcballControls";
import { polyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import async from "async";

import { transition } from 'd3-transition'
import { easeExpInOut, easeCircleInOut } from 'd3-ease'
import { cropImageFromCanvas } from "../../../../comm/comm";
import { CustomEventManager } from "../../../global/CustomEventManager";

polyfill({
    // use this to make use of the scroll behaviour
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});
class Canvas {
    constructor(threeRef, setLoadingDone, setBall, setting, productData, dragBall, setSelBall, toPreview, preloadBalls, changeBtn, previewBtnRef, buttonsData) {
        this.toPreview = toPreview;
        this.threeRef = threeRef;
        this.dragBall = dragBall;
        this.setLoadingDone = setLoadingDone;
        this.setBall = setBall;
        this.productData = productData;
        this.settingObj = setting;
        this.setSelBall = setSelBall;
        this.preloadBalls = preloadBalls;
        this.changeBtn = changeBtn;
        this.previewBtn = previewBtnRef.current;
        this.buttonsData = [1, 2, 3];
        this.canPreview = [];
        this.cameraX = 0;
        this.cameraY = 0;
        this.cameraZ = 0;
        this.mygui = new MyGui(this);
        this.render = this.render.bind(this);
        this.resize = this.resize.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleDouble = this.handleDouble.bind(this);
        this.firstLoaded = this.firstLoaded.bind(this);
        this.widthPercent = 1;
        this.heightPercent = 1;
        this.mouse = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
        this.intersectedArr = [];//選取到的珠珠
        this.timeout = 0;//選取到珠珠多久 關聯其他珠珠
        //旋轉畫面用
        this.inited = false;
        this.pointerDown = false;
        this.moveAxis = 0;//1x 2y
        this.pointer = {
            x: 0,
            y: 0
        };
        //教學
        this.customEventManager = new CustomEventManager();
        this.buttonsData = buttonsData;//用來在重置角度後，找出目前toggle在哪，並發出事件 
        //畫面連點2下
        this.clickT1 = 0;
        if (this.deviceType == "desktop") {
            this.widthPercent = 1;
            this.heightPercent = ((window.innerHeight * 0.82) - 70) / window.innerHeight;
        }
        else {
            this.widthPercent = 1;
            this.heightPercent = (window.innerHeight - 190) / window.innerHeight;//要先減掉 下方的254px+20px的保留空間
        }
        this.vp = {
            width: window.innerWidth * this.widthPercent,
            height: window.innerHeight * this.heightPercent,
            dpr: Math.min(devicePixelRatio, 2 || 1)
        };
        this.setup().then(() => {
            this.setLoadingDone(100);
            let self = this;
            const lastCanvas = this.threeRef.current.querySelector("canvas");
            if (lastCanvas) {
                this.threeRef.current.removeChild(lastCanvas);
            }
            else {
                this.mygui.addGui();
            }
            this.threeRef.current.appendChild(this.renderer.domElement);
            this.previewBtn.onclick = (e) => { this.exportFn(e) };
            setTimeout(() => {
                this.checkBalls();
                self.inited = true;
            }, 500);

        });
    }


    //旋轉完畢
    ready() {
        let pos = new THREE.Vector3();
        let ball = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        this.comm.changeEmissiveColor(ball, { emissive: "ff3333" });
        ball.getWorldPosition(pos);
        pos.project(this.camera);

        let widthHalf = this.vp.width / 2;
        let heightHalf = this.vp.height / 2;

        pos.x = (pos.x * widthHalf) + widthHalf + 70;
        pos.y = - (pos.y * heightHalf) + heightHalf;
        pos.z = 0;
        this.render();
    }
    readyForV4V5() {
        this.controls.reset();
        this.controls.applyTransformMatrix(this.controls.scale(5, new THREE.Vector3(0, 0, 0), true));
        this.scene.rotation.set(THREE.Math.degToRad(-85), 0, 0);

        let pos = new THREE.Vector3();
        let ball = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        this.comm.changeEmissiveColor(ball, { emissive: "ff0000" });
        ball.getWorldPosition(pos);
        pos.project(this.camera);

        let widthHalf = this.vp.width / 2;
        let heightHalf = this.vp.height / 2;

        pos.x = (pos.x * widthHalf) + widthHalf + 70;
        pos.y = - (pos.y * heightHalf) + heightHalf;
        pos.z = 0;
        this.clearTeachTip();
        this.render();
    }
    clearTeachTip() {
        const teachTip = this.threeRef.current.querySelector("#teachTip");
        if (teachTip)
            teachTip.parentElement.removeChild(teachTip);
    }
    //v3時 尾珠變色
    teachOtherTail(color = "ff0000") {
        if (!this.controls || this.settingObj.v !== "v3")
            return;
        this.controls.reset();
        this.controls.applyTransformMatrix(this.controls.scale(0.19960130098179493, new THREE.Vector3(0, 0, 0), true));
        this.scene.rotation.set(THREE.Math.degToRad(-60), THREE.Math.degToRad(270), 0);
        let ball = this.comm.getTailBall(this.settingObj, this.scene.children);
        this.comm.changeEmissiveColor(ball, { emissive: color });
        this.render();
    }
    teachOtherBeadsForV4V5() {
        let pos = new THREE.Vector3();
        let ball_middle = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        let ball = this.comm.getBallByPos(this.settingObj, this.scene.children, 102);
        this.comm.changeEmissiveColor(ball, { emissive: "ff0000" });
        this.render();
    }
  
    //"v1"  "v2" "v3" 教學時，第二顆珠子變色
    teachOtherBeads() {
        let pos = new THREE.Vector3();
        let ball_middle = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        let ball = this.comm.getBallByPos(this.settingObj, this.scene.children, ball_middle.pos + 2);
        this.comm.changeEmissiveColor(ball, { emissive: "ff0000" });
        this.render();
    }
    //v1 v2 v5 v6 主珠變色
    teachMiddleBeads() {
        let pos = new THREE.Vector3();
        let ball_middle = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        let ball = this.comm.getBallByPos(this.settingObj, this.scene.children, ball_middle.pos);
        this.comm.changeEmissiveColor(ball, { emissive: "ff0000" });
        this.render();
    }
    teachPendant() {
        this.controls.reset();
        this.controls.applyTransformMatrix(this.controls.scale(5, new THREE.Vector3(0, 0, 0), true));
        this.scene.rotation.set(THREE.Math.degToRad(-85), 0, 0);

        let pos = new THREE.Vector3();
        let ball = this.comm.getBallByPos(this.settingObj, this.scene.children, 1);
        this.comm.changeEmissiveColor(ball, { emissive: "ff0000" });

        ball.getWorldPosition(pos);
        pos.project(this.camera);

        /*  let widthHalf = this.vp.width / 2;
         let heightHalf = this.vp.height / 2;
 
         pos.x = (pos.x * widthHalf) + widthHalf + 70;
         pos.y = - (pos.y * heightHalf) + heightHalf;
         pos.z = 0; */
        this.clearTeachTip();
        /* let arrow = document.createElement("div");
        arrow.id = "teachTip";
        arrow.innerHTML = "There are three places you can drop to add the pendant.";
        if (this.deviceType == "desktop")
            arrow.style = "position: absolute;left: " + pos.x + "px;top: " + pos.y + "px;background: #ffffffcc;padding: 16px 10px;";
        else
            arrow.style = "position: absolute;background: #ffffffcc;padding: 10px;width: auto;bottom: 19%;";
        this.threeRef.current.appendChild(arrow); */
        this.render();
    }
    async checkBalls() {
        var selBall = [];
        //新增一項工作，把中心珠撈出來，如果產品是v1的話，要用中心珠的連接珠資料，變更連接珠
        let middleBall = this.comm.getMiddleBall(this.settingObj, this.scene.children);
        if (this.settingObj.v == "v1") {
            let middleBallAsset = this.comm.getBallAssets(middleBall.nid);
            if (middleBallAsset.connect) {
                let connectBalls = this.comm.getConnectBall(this.settingObj, this.scene.children);
                for (var k in connectBalls) {
                    this.scene.remove(connectBalls[k]);
                    this.scene.add(await this.comm.loadGltf(middleBallAsset.connect.id, connectBalls[k].pos, connectBalls[k].position.x, connectBalls[k].position.y, connectBalls[k].position.z, connectBalls[k].scale.x, connectBalls[k].rotation.y, connectBalls[k].arg1));
                }
            }
            this.render();
        }
        this.canPreview = [];
        for (var i = this.scene.children.length - 1; i >= 0; i--) {
            var ball = this.scene.children[i];
            if (ball.name == "default") {
                this.canPreview.push(ball.arg1);
            }
            else {
                if (this.settingObj.v == "v3" && ball.arg1 == 5) {
                    selBall.push({ price: ball.price, id: ball.nid, image: ball.image, name: ball.name, pos: ball.pos, arg1: ball.arg1, laser: ball.laser });
                }
                else if (ball.price) {
                    selBall.push({ price: ball.price, id: ball.nid, image: ball.image, name: ball.name, pos: ball.pos, arg1: ball.arg1, laser: ball.laser });
                }
            }
        }
        this.setSelBall(selBall);
        localStorage.setItem('ball', JSON.stringify(selBall));
    }
    async setup() {
        this.comm = new Comm();
        this.setLoadingDone(5);
        this.mygui.addVData(this.productData);
        await this.createScene();
        this.setLoadingDone(12);
        this.comm.ballAssets = this.mygui.getAllBead();
        this.camera.position.set(0, 100, 0);
        window.addEventListener("resize", this.resize);
        this.addEvents();
        await this.drawBody();
        this.setLoadingDone(80);
        this.controls = new ArcballControls(this.camera, this.renderer.domElement, this.scene);
        this.controls.addEventListener('change', this.render);
        this.controls.addEventListener('panEnd', () => {
            this.customEventManager.dispatch("teach", { action: "move" });
        });
        /* this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.addEventListener('change', this.render); */
        this.controls.enableRotate = false;
        this.controls.enableZoom = true;
        this.controls.enablePan = true;//要想辦法把 mousedown的事件 跟 pen分開
        this.controls.minDistance = 5;
        this.controls.maxDistance = 20;
        //this.controls.target.set(0, 0, 0);
        this.controls.setGizmosVisible(false)
        this.fitCameraToObject(this.camera, this.settingObj.target, 1, this.controls);
        this.controls.update();
        if (this.settingObj.v == "v1" || this.settingObj.v == "v2" || this.settingObj.v == "v3" || this.settingObj.v == "v6")
            this.scene.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), THREE.Math.degToRad(-75));
        this.render();
    }
    fitCameraToObject = function (camera, object, offset, orbitControls) {
        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject(object);

        var middle = new THREE.Vector3();
        var size = new THREE.Vector3();
        boundingBox.getSize(size);

        const fov = camera.fov * (Math.PI / 180);
        const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
        let dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
        let dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
        let cameraZ = Math.max(dx, dy);

        // offset the camera, if desired (to avoid filling the whole canvas)
        if (offset !== undefined && offset !== 0) cameraZ *= offset;

        // set the far plane of the camera so that it easily encompasses the whole object
        const minZ = boundingBox.min.z;
        const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;

        camera.far = cameraToFarEdge * 3;
        camera.updateProjectionMatrix();

        if (orbitControls !== undefined) {
            // set camera to rotate around the center
            orbitControls.target = new THREE.Vector3(0, 0, 0);

            // prevent camera from zooming out far enough to create far plane cutoff
            if (this.settingObj.v == "v1" || this.settingObj.v == "v2" || this.settingObj.v == "v3") {
                orbitControls.maxDistance = cameraToFarEdge * 0.801;
            }
            else {
                orbitControls.maxDistance = cameraToFarEdge * 1;
            }
        }
    }
    async resetRotation(e) {
        this.controls.reset();
        this.controls.applyTransformMatrix(this.controls.scale(0.19960130098179493, new THREE.Vector3(0, 0, 0), true));
        switch (this.settingObj.v) {
            case "v1":
                this.scene.rotation.set(THREE.Math.degToRad(-75), THREE.Math.degToRad(-90), 0);
                break;
            case "v2":
                this.scene.rotation.set(THREE.Math.degToRad(-75), THREE.Math.degToRad(-90 + (360 / this.settingObj.n)), 0);
                break;
            case "v3":
                this.scene.rotation.set(THREE.Math.degToRad(-75), THREE.Math.degToRad(90), 0);
                break;
            case "v4":
                this.scene.rotation.set(0, 0, 0);
                break;
            case "v5":
                this.scene.rotation.set(0, 0, 0);
                break;
            case "v6":
                this.scene.rotation.set(THREE.Math.degToRad(-75), THREE.Math.degToRad(-90), 0);
                break;
        }
        this.render();
        this.customEventManager.dispatch("teach", { action: "reset" });
    }
    async exportFn(e) {
        if (this.inited == false) return;
        if (this.canPreview.length > 0) {
            //this.renderer.domElement.dispatchEvent(new TouchEvent("ss"));
            var _toggle, toggleName;
            for (var i = 0; i < this.canPreview.length; i++) {
                _toggle = this.mygui.getToggleNameByType(this.canPreview[i]);
                if (_toggle) {
                    toggleName = _toggle.name;
                    break;
                }
            }
            if (toggleName) {
                this.changeBtn(toggleName);
                return alert("沒有設定" + toggleName);
            }
        }
        this.controls.reset();
        this.controls.applyTransformMatrix(this.controls.scale(0.19960130098179493, new THREE.Vector3(0, 0, 0), true));
        switch (this.settingObj.v) {
            case "v1":
                this.scene.rotation.set(THREE.Math.degToRad(-60), THREE.Math.degToRad(-90), 0);
                break;
            case "v2":
                this.scene.rotation.set(THREE.Math.degToRad(-60), THREE.Math.degToRad(-90 + (360 / this.settingObj.n)), 0);
                break;
            case "v3":
                this.scene.rotation.set(THREE.Math.degToRad(-60), THREE.Math.degToRad(-90), 0);
                break;
            case "v4":
            case "v5":
                this.scene.rotation.set(THREE.Math.degToRad(0), THREE.Math.degToRad(0), 0);
                break;
            case "v6":
                this.scene.rotation.set(THREE.Math.degToRad(-60), THREE.Math.degToRad(-90 + (360 / this.settingObj.n)), 0);
                break;
        }
        this.render();

        const dataURL = this.renderer.domElement.toDataURL('image/png');
        var img = new Image();
        img.onload = () => {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            cropImageFromCanvas(ctx, [229, 229, 229, 255], true);
            var _dataURL = canvas.toDataURL('image/png');
            localStorage.setItem('tmppng', _dataURL);
        };
        img.src = dataURL;
        localStorage.setItem('basePrice', this.productData.basePrice);
        //save to localStorege and next page
        this.toPreview();
    }
    addDia(diaRef) {
        /* const geometry = new THREE.SphereGeometry(2, 12, 6);
        const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
        const sphere = new THREE.Mesh(geometry, material); */
        this.scene.add(diaRef.current);
        this.render();
    }
    async drawBody() {
        this.scene.clear();
        var product = this.mygui.getCurrectProduct();
        switch (this.settingObj._v) {
            case "v1":
                await v1(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
            case "v2":
                await v2(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
            case "v3":
                await v3(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
            case "v4":
                await v4(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
            case "v5":
                await v5(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
            case "v6":
                await v6(this.scene, this.comm, this.settingObj, this.render, product, this.preloadBalls);
                break;
        }

    }

    async createScene() {
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xE5E5E5);
        const rgbeLoader = new RGBELoader().setPath('./images/');
        const texture = await rgbeLoader.loadAsync('venice_sunset_1k.hdr');
        texture.mapping = THREE.EquirectangularReflectionMapping;
        this.scene.environment = texture;
        this.camera = new THREE.PerspectiveCamera(
            45,
            this.vp.width / this.vp.height,
            1,
            2000
        );
        this.camera.setRenderTarget = this.scene;
        //this.camera.view=this.scene;
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(this.vp.width, this.vp.height);
        this.renderer.setPixelRatio(this.vp.dpr);
        this.renderer.autoClear = true;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        /*  document.querySelector("#canvas").style.width = `${this.vp.width}px`;
         document.querySelector("#canvas").style.height = `${this.vp.height}px`; */

    }
    addEvents() {
        if ("ontouchmove" in window) {
            this.threeRef.current.addEventListener("touchstart", this.handleMouseDown);
            this.threeRef.current.addEventListener("touchmove", this.handleMouseMove);
            this.threeRef.current.addEventListener("touchend", this.handleMouseUp);
            this.threeRef.current.addEventListener("dblclick", this.handleDouble);
        } else {
            this.threeRef.current.addEventListener("mousedown", this.handleMouseDown);
            this.threeRef.current.addEventListener("mousemove", this.handleMouseMove);
            this.threeRef.current.addEventListener("mouseup", this.handleMouseUp);
            this.threeRef.current.addEventListener("dblclick", this.handleDouble);
        }
        this.customEventManager.addEventListener("startTeach", this.firstLoaded);
    }

    matchFn(target) {
        switch (this.settingObj._v) {
            case "v1":
                if (target.arg1 == this.dragBall.arg1)
                    return true;
                return false;
                break;
            case "v2":
            case "v3":
            case "v6":
                if (target.arg1 == this.dragBall.arg1)
                    return true;
                return false;
                break;
            case "v4":
                /* if ((this.dragBall.arg1 == 1) && (target.pos == 2 || target.pos == 3)) {
                    return true
                } */
                if (target.arg1 == this.dragBall.arg1)
                    return true;
                return false;
                break;
            case "v5":
                if (target.arg1 == this.dragBall.arg1)
                    return true;
                return false;
                break;
        }
    }
    clearSelect() {
        clearTimeout(this.timeout);
        for (var k in this.scene.children) {
            if (this.comm.isSelect(this.scene.children[k]))
                this.comm.unselect(this.scene.children[k]);
        }
        this.intersectedArr = [];
    }
    dispose = () => {
        this.renderer.dispose()
        this.scene.traverse(object => {
            if (!object.isMesh) return
            //console.log('dispose geometry!')
            object.geometry.dispose()

            if (object.material.isMaterial) {
                this.cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) this.cleanMaterial(material)
            }
        })
        this.scene.environment.dispose();
        this.scene.environment = null;
        this.scene = null;
        this.comm.disposeAll();
    }
    cleanMaterial = material => {
        //console.log('dispose material!')
        material.dispose()
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                //console.log('dispose texture!')
                value.dispose()
            }
        }
    }
    async offPendant(item) {
        let ball = this.comm.getBallByPos(this.settingObj, this.scene.children, 1);
        this.scene.remove(ball);
        this.scene.add(await this.comm.loadGltf(item.id, ball.pos, ball.position.x, ball.position.y, ball.position.z, ball.scale.x, ball.rotation.y, ball.arg1));
        this.render();
    }
    changeBaseColor(item) {
        let baseModel = this.comm.getBaseModeForStringColor(this.scene);
        if (!baseModel) return;
        baseModel.name =item.name;
        baseModel.nid =item.id;
        baseModel.price =item.price;
        baseModel.image =item.image;
        this.comm.changeEmissiveColor(baseModel, { emissive: item.effects.color });
        this.customEventManager.dispatch("teach", { action: "stringColor" });
        this.render();
        this.checkBalls();
    }
    render() {
        this.renderer.clear();
        this.renderer.render(this.scene, this.camera);
    }

    resize() {
        this.vp.width = window.innerWidth * this.widthPercent;
        this.vp.height = window.innerHeight * this.heightPercent;
        this.renderer.setSize(this.vp.width, this.vp.height);

        this.camera.aspect = this.vp.width / this.vp.height;
        this.camera.updateProjectionMatrix();
        this.render();
    }

    handleMouseDown(e) {
        if (!this.inited || e.button == 2) return;
        this.pointerDown = true;
        this.pointer.x = e.touches ? e.touches[0].clientX : e.clientX;
        this.pointer.y = e.touches ? e.touches[0].clientY : e.clientY;
        var offsetY = -128;
        if (this.deviceType != "desktop")
            offsetY = -30;
        this.mouse.x = ((e.clientX) / this.vp.width) * 2 - 1;
        this.mouse.y = - ((e.clientY + offsetY) / this.vp.height) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        var intersects = this.raycaster.intersectObjects(this.scene.children, true);
        for (var i = 0; i < intersects.length; i++) {
            if (intersects[i].object.isMesh && intersects[i].object.parent.nid) {
                this.mygui.showBallGui(intersects[i].object);
                break;
            }
        }

    }

    handleMouseMove(e) {
        if (!this.pointerDown) return;
        if(this.deviceType != "desktop"&&e.touches&&e.touches?.length>1)
            return;
        const x = e.touches ? e.touches[0].clientX : e.clientX;
        const y = e.touches ? e.touches[0].clientY : e.clientY;
        if (this.moveAxis == 0) {
            const threshold = 9;
            if (Math.abs(x - this.pointer.x) > threshold) {
                this.moveAxis = 1;
                return;
            }
            else if (Math.abs(y - this.pointer.y) > threshold) {
                this.moveAxis = 2;
                return;
            }
            return;
        }
        var velocity = 0;
        if (this.moveAxis == 1) {
            velocity = (x - this.pointer.x);
            this.scene.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), THREE.Math.degToRad(-velocity));
            this.render();
            this.pointer.x = x;
        }
        else {
            velocity = (y - this.pointer.y);
            this.scene.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), THREE.Math.degToRad(velocity));
            this.render();
            this.pointer.y = y;
        }

    }

    handleDouble() {
        this.resetRotation();
    }
    handleMouseUp() {
        /* const _t = new Date().getTime();
        if (_t - this.clickT1 < 800) {
            this.resetRotation();
        }
        this.clickT1 = _t; */
        if (this.moveAxis != 0) {
            this.customEventManager.dispatch("teach", { action: "rotate" });
        }
        this.pointerDown = false;
        this.moveAxis = 0;
    }
    firstLoaded(e) {
        if (this.scene) {
            let self = this;
            let initY = this.scene.rotation.y;
            transition()
                .duration(1750).tween("move", (_) => {
                    return function (t) {
                        const r = easeCircleInOut(t) * 360;
                        if (self.settingObj.v == "v1" || self.settingObj.v == "v2" || self.settingObj.v == "v3" || self.settingObj.v == "v6")
                            self.scene.rotation.y = THREE.Math.degToRad(r) + initY;
                        else
                            self.scene.rotation.z = THREE.Math.degToRad(r) + initY;
                        self.render();
                        if (t == 1) {
                            self.customEventManager.dispatch("teach", { action: "ready" });
                        }
                    }
                });
        }
    }
}

export { Canvas };