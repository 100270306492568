import * as THREE from 'three'
import { useState, useRef, useEffect, memo, useCallback } from 'react'
import { Canvas, useLoader, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, MeshRefractionMaterial, AccumulativeShadows, RandomizedLight, Html, Environment, Center, PresentationControls, ArcballControls } from '@react-three/drei'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { RGBELoader } from 'three-stdlib'
import { HexColorPicker } from 'react-colorful'
import Ring from './Ring'
import Cufflinks from './Cufflinks'
import { CustomEventManager } from "../../global/CustomEventManager";
import { cropImageFromCanvas, replaceColorWithTransparent, wait } from '../../../comm/comm'
import { transition } from 'd3-transition'
import { easeExpInOut, easeCircleInOut } from 'd3-ease'
import { useTranslation } from "react-i18next";
import Ring2 from './Ring2'
const customEventManager = new CustomEventManager();
const ReactCanvas = ({ productsName, pid,set_isLoading, setLoadingDone, previewBtnRef, toPreview, preloadBalls }) => {
    const { t, i18n } = useTranslation();
    const [ready, set_ready] = useState(false);
    useEffect(() => {
        set_ready(true);
    }, []);
    function RanderCanvas() {
        const canvasRef = useRef();
        const ringRef = useRef();
        const cntlRef = useRef();
        const [mygl, set_mygl] = useState(null);
        const [levels, set_levels] = useState(9);
        const mycamera = useRef();
        const InitThree = () => {
            const gl = useThree((state) => state.gl);
            set_mygl(gl);
            const { camera } = useThree();
            console.log("InitThree camera", camera)
            mycamera.current = camera;
        }
        const handleDoubleClick = () => {
            cntlRef.current.reset();
            customEventManager.dispatch("teach", { action: "reset" });
        };
        var pointerDown = false;
        var moveAxis = 0;//1x 2y
        var maxPointNumber = 0;//記錄在手機托曳中 最多使用了幾根手指(2根是移動)
        var pointer = {
            x: 0,
            y: 0
        };

        const handleMouseDown = (e) => {
            lastRotation = { x: mycamera.current.rotation.x, y: mycamera.current.rotation.y, z: mycamera.current.rotation.z };
            pointerDown = true;
            pointer.x = e.touches ? e.touches[0].clientX : e.clientX;
            pointer.y = e.touches ? e.touches[0].clientY : e.clientY;
        }
        const handleMouseMove = (e) => {
            if (!pointerDown) return;
            if (e.changedTouches && e.changedTouches.length > maxPointNumber)
                maxPointNumber = e.changedTouches.length;
            const x = e.touches ? e.touches[0].clientX : e.clientX;
            const y = e.touches ? e.touches[0].clientY : e.clientY;
            if (moveAxis == 0) {
                const threshold = 9;
                if (Math.abs(x - pointer.x) > threshold) {
                    moveAxis = 1;
                    return;
                }
                else if (Math.abs(y - pointer.y) > threshold) {
                    moveAxis = 2;
                    return;
                }
                return;
            }
            var velocity = 0;
            if (moveAxis == 1) {
                velocity = (x - pointer.x);
                pointer.x = x;
            }
            else {
                velocity = (y - pointer.y);
                pointer.y = y;
            }

        }
        let lastRotation = {};
        const handleMouseUp = (e) => {
            if (moveAxis != 0) {
                if (lastRotation.x === mycamera.current.rotation.x && lastRotation.y === mycamera.current.rotation.y && lastRotation.z === mycamera.current.rotation.z ||
                    (maxPointNumber === 2))
                    customEventManager.dispatch("teach", { action: "move" });
                else
                    customEventManager.dispatch("teach", { action: "rotate" });

            }
            pointerDown = false;
            moveAxis = 0;
        }
        const firstLoaded = async () => {
            await new Promise(resolve => requestAnimationFrame(resolve));
            let initY = productsName === "ring" ? ringRef.current.rotation.z : ringRef.current.rotation.y;
            transition()
                .duration(1750).tween("move", (_) => {
                    return function (t) {
                        const r = easeCircleInOut(t) * 360;
                        if (productsName === "ring")
                            ringRef.current.rotation.z = THREE.Math.degToRad(r) + initY;
                        else
                            ringRef.current.rotation.y = THREE.Math.degToRad(r) + initY;
                        if (t == 1) {
                            customEventManager.dispatch("teach", { action: "ready" });
                        }
                    }
                });
        };

        function waitAnimationFrame() {
            return new Promise(resolve => requestAnimationFrame(resolve));
        }
        async function processAngle(angle, index) {
            ringRef.current.rotation.set(angle.x, angle.y, angle.z);
            await waitAnimationFrame();
            var dataURL = mygl.domElement.toDataURL('image/png');
            var img = new Image();

            return new Promise((resolve) => {
                img.onload = () => {
                    var canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    var ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    cropImageFromCanvas(ctx, [229, 229, 229, 255], true);
                    var _dataURL = canvas.toDataURL('image/png');
                    if (productsName === "ring")
                        localStorage.setItemByLang(i18n.language, "tmpPNG", `${pid}/${index + 1}`, _dataURL);
                    else
                        localStorage.setItem('tmppng', _dataURL);
                    resolve();
                };
                img.src = dataURL;
            });
        }
        const exportFn = async () => {
            console.log("cjc")
            //把強度改成1 不然輸出會泛光
            set_levels(1);
            await waitAnimationFrame();
            if (ringRef.current) {
                cntlRef.current.reset();
                var angleArr = [];
                if (productsName === "ring")
                    angleArr = [
                        { x: Math.PI / 1.8, y: 0, z: -Math.PI / 2.3 },
                        { x: Math.PI / 2, y: 0, z: 0 },
                        { x: Math.PI, y: 0, z: 0 },
                    ];
                else if (productsName === "cufflinks" || productsName === "tiebar" || productsName === "cuff")
                    angleArr = [
                        { x: 0, y: 0, z: 0 },
                    ];
                for (const [index, angle] of angleArr.entries()) {
                    await processAngle(angle, index);
                }
                toPreview();
            }
        }
        useEffect(() => {
            window.addEventListener("dblclick", handleDoubleClick);
            customEventManager.addEventListener("startTeach", firstLoaded);
            if (canvasRef.current) {
                canvasRef.current.addEventListener("mousemove", handleMouseMove);
                canvasRef.current.addEventListener("mousedown", handleMouseDown);
                canvasRef.current.addEventListener("mouseup", handleMouseUp);
                canvasRef.current.addEventListener("touchstart", handleMouseDown);
                canvasRef.current.addEventListener("touchmove", handleMouseMove);
                canvasRef.current.addEventListener("touchend", handleMouseUp);
            }
            return () => {
                window.removeEventListener("dblclick", handleDoubleClick);
                customEventManager.removeEventListener("startTeach", firstLoaded);
                if (canvasRef.current) {
                    canvasRef.current.removeEventListener("mousemove", handleMouseMove);
                    canvasRef.current.removeEventListener("mousedown", handleMouseDown);
                    canvasRef.current.removeEventListener("mouseup", handleMouseUp);
                    canvasRef.current.removeEventListener("touchstart", handleMouseDown);
                    canvasRef.current.removeEventListener("touchmove", handleMouseMove);
                    canvasRef.current.removeEventListener("touchend", handleMouseUp);
                }
            };
        }, []);
        useEffect(() => {
            if (mygl)
                previewBtnRef.current.onclick = (e) => { exportFn(e) };
        }, [mygl]);

        const texture = useLoader(RGBELoader, productsName === "cuff" || productsName === "ring" ? './images/HDR/brown_photostudio_04_4k.hdr' : './images/venice_sunset_1k.hdr', null, e => {
            //console.log("====================useLoader",e.loaded / e.total * 100 * 0.2)
            setLoadingDone(e.loaded / e.total * 100 * 0.2)
        });
        texture.mapping = THREE.EquirectangularReflectionMapping;
        return (
            <Canvas /* style={{height:"200px"}} */ ref={canvasRef} shadows gl={{ preserveDrawingBuffer: true }} camera={{ position: [0, 0, 15], fov: 35, near: 1, far: 60 }}>
                <InitThree />
                <color attach="background" args={['#E5E5E5']} />
                <ambientLight intensity={0.5} />
                <Environment map={texture} />
                <ArcballControls
                    enablePan
                    enableRotate
                    enableZoom
                    maxDistance={50}
                    ref={cntlRef}
                />
                {
                    productsName === "ring" &&
                    <Ring set_isLoading={set_isLoading} ringRef={ringRef} map={texture} rotation={[(Math.PI / 1.8), 0, 0]} scale={.25} setLoadingDone={setLoadingDone} preloadBalls={preloadBalls} />
                }
                {
                    (productsName === "cufflinks" || productsName === "tiebar" || productsName === "cuff") &&
                    <Cufflinks ringRef={ringRef} map={texture} rotation={[0, 0, 0]} scale={productsName === "cuff" ? 0.5 : 1} partCount={productsName === "cuff" ? 1 : 2} setLoadingDone={setLoadingDone} preloadBalls={preloadBalls} />
                }
                <EffectComposer>
                    <Bloom luminanceThreshold={1} intensity={0.85} levels={levels} mipmapBlur />
                </EffectComposer>
            </Canvas>
        );
    }
    if (ready) {
        return (
            <RanderCanvas />
        );
    }
    else {
        return (<div />);
    }
}

export default memo(ReactCanvas);