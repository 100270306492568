import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";
const MessageBtn = ({ styleIndex }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    function getStyle() {
        if (isMobile) {
            switch (styleIndex) {
                default:
                    return {
                        position: 'fixed',
                        width: '48px',
                        height: '48px',
                        left: '20px',
                        bottom: '20px',
                        cursor: 'pointer',
                        zIndex: 1,
                    };
            }
        }
        else {
            switch (styleIndex) {
                default:
                    return {
                        position: 'fixed',
                        width: '60px',
                        height: '60px',
                        left: '20px',
                        bottom: '20px',
                        cursor: 'pointer',
                        zIndex: 1,
                    };
                case "editor":
                    return {
                        position: 'absolute',
                        width: '60px',
                        height: '60px',
                        right: '48px',
                        bottom: '28px',
                        cursor: 'pointer',
                        zIndex: 1,
                    };
            }
        }
    }
    return (
        <div className="app">
            <img src="./images/messenger.jpeg" style={getStyle()} onClick={e=>{
                var newWindow = window.open(i18n.language==='en'?'http://m.me/1237150356322077':'http://m.me/120341367683921', '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }}/>
        </div>
    );
}

export default MessageBtn;