import * as THREE from "three";
export default async (scene, comm, settingObj, renderFn,product,preloadBalls) => {
  var n = settingObj.n;
  var r1 = settingObj.r1;
  var sn = n + 3;
  var x = 0;
  var z = 0;
  var rotation = 0;
  var _ang = (360 / (sn - 1.4));//圓周只算n-1顆
  var deg_to_pi = Math.PI * 2 / 360;
  var r2 = (r1 / Math.sin(_ang / 2 * deg_to_pi));
  settingObj.r2 = r2;
  var ang = -(_ang * .5);
  var arg1 = 0;
  var _r1 = r1;
  //1中心珠,2一般珠,3連接珠
  for (var i = 0; i < sn; i++) {
    var deg = ang * deg_to_pi;
    var nid = 0;
    //前三顆
    if (i == 0) {
      arg1 = 3;
      nid = 1003;
      ang += (_ang * .5);
    }
    else if (i == 1) {
      arg1 = 1;
      nid = 1002;
      ang += (_ang * .5);
    }
    else if (i == 2) {
      arg1 = 3;
      nid = 1003;
      ang += (_ang * 0.8);
    }
    else {
      arg1 = 2;
      ang += _ang;
      nid = 1001;
    }
    x = r2 * Math.cos(deg);
    z = r2 * Math.sin(deg);
    rotation = -deg;
    if (i == 2)
      rotation += Math.PI;
    scene.add(await comm.loadGltf(comm.loadTempBall(preloadBalls,i,nid), i, x, r1, z, _r1, rotation, arg1));
  }
  scene.rotation.y = -90 * deg_to_pi;

  const geometry = new THREE.SphereGeometry(r2, 32, 16);
  const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
  const sphere = new THREE.Mesh(geometry, material);
  //sphere.visible = false;
  //scene.add(sphere);
  settingObj.target = sphere;

  renderFn();
  return r2;
};
