import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const loader = new GLTFLoader();

export default (url, id) => {
  console.log("~~~~url", url)
  return new Promise((resolve, reject) => {
      loader.load(url, gltf => {
        resolve(gltf.scene);
      },press=>{},error=>{console.log("loader error",error);reject(error)});
   
  });
};
