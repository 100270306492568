import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import style from "./index.module.css";
import styleUi from "./LoadRecord2.module.scss";
import allStyle from "../desktop/global/all.module.scss";
import { v4 } from "uuid";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';

const LoadRecord2 = ({ setModalType, arg }) => {
    const [inProp, setInProp] = useState(false);
    useEffect(() => {
        setInProp(true);
    }, [])

    return (
        <div className={style.modal}>
            <CSSTransition in={inProp} timeout={{
                appear: 0,
                enter: 300,
                exit: 0,
            }} classNames={{
                enterActive: style.OpacityEnterActive,
                enterDone: style.OpacityEnterDone,
                exitActive: style.OpacityExit,
                exitDone: style.OpacityExitActive
            }}
            >
                <div className={`${style.Opacity} ${style.modal}`} >
                    <CSSTransition in={inProp} timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 0,
                    }} classNames={{
                        enterActive: style.PopupPageEnterActive,
                        enterDone: style.PopupPageEnterDone,
                        exitActive: style.PopupPageExit,
                        exitDone: style.PopupPageExitActive
                    }}
                        onExited={async () => { 
                            await new Promise(resolve => requestAnimationFrame(resolve));
                            setModalType(prev => (prev.slice(0, -1)));
                        }}
                    >
                        <div className={`${style.PopupPage} ${style.page}`} id="background" onClick={event => {
                            if (event.target.id == "background") {
                                //setInProp(false)
                            }
                        }}>
                            <div className={styleUi.app}>
                                <h1>{arg.title}</h1>
                                <div className={styleUi.btns}>
                                    {
                                        arg.btns.map((btn,k) => {
                                            return <button className={k===1?allStyle.btnBlack:allStyle.btnWhite} key={v4()} onClick={e => {
                                                setInProp(false);
                                                btn.cb();
                                            }}>{btn.label}</button>
                                        })
                                    }
                                </div>
                                {arg.note &&
                                    <span>{arg.note}</span>
                                }
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
}

export default memo(LoadRecord2);