import { useState, useEffect, useRef, Fragment } from "react";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidEmail, isValidPhoneNumber } from "../../../comm/comm";
import allStyle from "../../global/all.module.scss";
const Page4 = ({ set_step, name, set_name, mail, set_mail, prePhone, set_prePhone, phone, set_phone ,submitIdeas}) => {
    const { t, i18n } = useTranslation();
    const [today, setToday] = useState('');
    const [maxDay, setMaxDay] = useState('');
    const nameRef = useRef();
    const mailRef = useRef();
    const [mailErr, set_mailErr] = useState('');
    const [phoneErr, set_phoneErr] = useState('');

    const nextStep = () => {
        if (name === "")
            nameRef.current.focus();
        else if (isValidEmail(mail) === false) {
            set_mailErr(mail !== "" ? "wrong format" : "");
            mailRef.current.focus();
        }
        else if (isValidPhoneNumber(phone) === false) {
            set_phoneErr(phone !== "" ? "wrong format" : "");
        }
        else
        {
            set_mailErr("");
            set_phoneErr("");
            submitIdeas();
        }
            
    };
    return (
        <div className={style.page4View}>
            <h1>{t(`customTitle_4`)}</h1>
            <h2>{t(`customSubTitle_4`)}</h2>
            <div className={style.info}>
                <div className={style.inputBox}>
                    <div className={style.box}>
                        <label>{t('fullName')}</label>
                        <input ref={nameRef} id="name" name="name" type="text" value={name}
                            onChange={e => { set_name(e.target.value) }} />
                    </div>
                    <span></span>
                </div>
                <div className={style.inputBox}>
                    <div className={style.box}>
                        <label>{t('email')}</label>
                        <input ref={mailRef} type="email" id="email" name="email" value={mail}
                            onChange={e => { set_mail(e.target.value) }}
                        ></input>
                    </div>
                    <span>{mailErr}</span>
                </div>
                <div className={style.inputBox}>
                    <div className={style.box}>
                        <label>{t('mobile')}</label>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true
                            }}
                            className={style.phone}
                            enableAreaCodeStretch
                            enableAreaCodes={['us', 'tw']}
                            country={i18n.language === "en" ? "us" : "tw"}
                            enableSearch={true}
                            value={phone}
                            onChange={(phone) => set_phone(phone)}
                        />
                    </div>
                    <span>{phoneErr}</span>
                </div>
            </div>
            <div className={style.btns}>
                <button className={allStyle.btnWhite} onClick={e => {
                    set_step(prev => prev - 1);
                }}>{t('back')}</button>
                <button className={allStyle.btnBlack} onClick={e => {
                    nextStep();
                }}>{t('submit')}</button>
            </div>
        </div>
    );
}

export default Page4;