import { useState, useEffect, useRef, Fragment } from "react";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@mui/material";
import { formatDate } from "../../../comm/comm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import allStyle from "../../global/all.module.scss";
const Page3 = ({ set_step, specialDay, set_specialDay, limitDays, dayNotSure, set_dayNotSure }) => {
    const { t, i18n } = useTranslation();
    const dayPickRef = useRef();
    const [maxDay, setMaxDay] = useState('');
    useEffect(() => {
        var today = new Date();
        // 获取 30 天后的日期
        var thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + limitDays);
        set_specialDay(thirtyDaysFromNow);
        setMaxDay(thirtyDaysFromNow);
    }, [])

    const nextStep = () => {
        if (specialDay === ""&&dayNotSure===false) {
            dayPickRef.current.focus();
        }
        else
            set_step(prev => prev + 1);
    };
    return (
        <div className={style.page3View}>
            <h1>{t(`customTitle_3`)}</h1>
            <h2>{t(`customSubTitle_3`)}</h2>
            <div className={style.selectDay}>
                <div className={style.timePickerBox}>
                    <img src="./images/datePick.png" />
                    <DatePicker  className={style.timePicker} disabled={dayNotSure} ref={dayPickRef} 
                    dateFormat="yyyy/MM/dd"  
                    selected={specialDay} 
                    onChange={(date) => {
                        set_specialDay(date)
                    }} 
                    minDate={maxDay}/>
                </div>
                <FormControlLabel className={style.checkbox} control={<Checkbox checked={dayNotSure} onClick={e => {
                    set_dayNotSure(prev => !prev);
                }} />} label={t('notSure')} />
            </div>
            <div className={style.btns}>
                <button className={allStyle.btnWhite} onClick={e => {
                    set_step(prev => prev - 1);
                }}>{t('back')}</button>
                <button className={allStyle.btnBlack} onClick={e => {
                    nextStep();
                }}>{t('next')}</button>
            </div>
        </div>
    );
}

export default Page3;