import { useState, useEffect, useRef, Fragment, memo, useMemo } from "react";
import style from "./index.module.css";
import styleUi from "./Loading.module.scss";
import allStyle from "../desktop/global/all.module.scss";
import { v4 } from "uuid";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';
import { CustomEventManager } from "../desktop/global/CustomEventManager";
const customEventManager = new CustomEventManager();
const LoadingBar = styled.div`
    height: 100%;
    width: ${props => props.process}%;
    color: #000 !important;
    background: linear-gradient(270deg, #FECC86 0%, #D49234 100%);
    `;
const Loading = ({ setModalType, arg }) => {
    const [inProp, setInProp] = useState(false);
    const [process, set_process] = useState(0);
    useEffect(() => {
        setInProp(true);
        arg.fnArr.doCloseFn = () => {
            setInProp(false);
        }
        arg.fnArr.setProcessFn = (_process) => {
            set_process(_process);
        }
        arg.fnArr.setProcessTimeFn = (time) => {
            const startTime = Date.now();
            const animate = () => {
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTime;
                const currentProgress = Math.min(1, elapsedTime / time);
                const mappedProgress = Math.round(currentProgress * 100);
                set_process(mappedProgress);
                if (currentProgress < 1) {
                    requestAnimationFrame(animate);
                }
            };
            requestAnimationFrame(animate);
        }
        if (arg.processTime) {
            arg.fnArr.setProcessTimeFn(arg.processTime);
        }
        customEventManager.addEventListener("_modal_Loading", onModalCmd);
        return () => {
            customEventManager.removeEventListener("_modal_Loading", onModalCmd);
        };
    }, [])
    function onModalCmd(data) {
        switch (data.cmd) {
            case "close":
                setInProp(false);
                break;
            case "process":
                set_process(data.value);
                break;
        }
    }
    return (
        <div className={style.modal}>
            <CSSTransition in={inProp} timeout={{
                appear: 0,
                enter: 300,
                exit: 0,
            }} classNames={{
                enterActive: style.OpacityEnterActive,
                enterDone: style.OpacityEnterDone,
                exitActive: style.OpacityExit,
                exitDone: style.OpacityExitActive
            }}
            >
                <div className={`${style.Opacity} ${style.modal}`} >
                    <CSSTransition in={inProp} timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 0,
                    }} classNames={{
                        enterActive: style.PopupPageEnterActive,
                        enterDone: style.PopupPageEnterDone,
                        exitActive: style.PopupPageExit,
                        exitDone: style.PopupPageExitActive
                    }}
                        onExited={async () => {
                            await new Promise(resolve => requestAnimationFrame(resolve));
                            setModalType(prev => (prev.slice(0, -1)));
                        }}
                    >
                        <div className={`${style.PopupPage} ${style.page}`} id="background" onClick={event => {
                            if (event.target.id == "background") {
                                //setInProp(false)
                            }
                        }}>
                            <div className={styleUi.app}>
                                <div>
                                    <img src="./images/logo_icon.png" />
                                    <p>LOADING... {process}%</p>
                                </div>
                                <div><LoadingBar process={process} /></div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>
        </div>
    );
}

export default memo(Loading);