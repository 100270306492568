import * as THREE from 'three'
import { useState, useRef, useEffect, memo } from 'react'
import { useGLTF, MeshRefractionMaterial, AccumulativeShadows, RandomizedLight, Html, Environment, Center, PresentationControls, ArcballControls } from '@react-three/drei'
import { CustomEventManager } from '../../global/CustomEventManager'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { createCounterFn, wait } from '../../../comm/comm';
import { HexColorPicker } from 'react-colorful';

const loader = new GLTFLoader();
const customEventManager = new CustomEventManager();
const Cufflinks = ({ map, setLoadingDone, ringRef, preloadBalls, partCount, ...props }) => {
    const [node_body, set_node_body] = useState([]);
    const [node_body2, set_node_body2] = useState([]);
    const beadsRef = useRef([]);
    const [diamondColor, setDiamondColor] = useState('black')
    const [metalColor, setMetalColor] = useState('white');
    const loaderPartModel = (path, setFn) => {
        return new Promise(async (resolve, reject) => {
            //await wait(1000);
            try {
                loader.load(path, gltf => {
                    setFn(gltf.scene.children.reverse());
                    resolve(0)
                }, e => { console.log("...process", e) }, error => { resolve(0); });
            } catch (error) {
                resolve(0);
            }
        });
    }

    useEffect(() => {
        const beadsStr = localStorage.getItem("beads");
        if (beadsStr) {
            beadsRef.current = JSON.parse(beadsStr);
        }
        if (partCount === 1) {
            changePart(preloadBalls.current[0], () => {
                setLoadingDone(100);
            });
        }
        else {
            const timeFn = createCounterFn(2, (counter, times) => {
                setLoadingDone(counter / times * 100);
            });
            changePart(preloadBalls.current[0], timeFn);
            changePart2(preloadBalls.current[1], timeFn);
        }
        customEventManager.addEventListener("changePart", changePart);
        customEventManager.addEventListener("changePart2", changePart2);
        customEventManager.addEventListener("ch_color", ch_color);
        return () => {
            customEventManager.removeEventListener("changePart", changePart);
            customEventManager.removeEventListener("changePart2", changePart2);
            customEventManager.removeEventListener("ch_color", ch_color);
        };
    }, []);
    const ch_color = async (color) => {

    }
    const changePart = async (item, doneCb) => {
        const obj = beadsRef.current.find(v => v.id === item.id);
        if (obj) {
            await loaderPartModel(`./models/${obj.model}.gltf`, set_node_body);
            setDiamondColor("black")
            setMetalColor("white")
            await wait(1);
            //get color
            if (obj?.effects) {
                setDiamondColor(`#${obj.effects.diamondColor}`);
                setMetalColor(`#${obj.effects.bodyColor}`);
            }
            else
            {
                console.log("no color")
                //setMetalColor('white');
            }

            if (doneCb)
                doneCb();

        }

    }
    const changePart2 = async (item, doneCb) => {
        const obj = beadsRef.current.find(v => v.id === item.id);
        if (obj) {
            await loaderPartModel(`./models/${obj.model}.gltf`, set_node_body2);
            if (doneCb)
                doneCb();
        }
    }
    return (
        <group {...props} dispose={null} ref={ringRef}>
            {node_body.map(v => {
                if (v.name.indexOf("_diamonds") > -1)
                    return <mesh key={v.name} geometry={v.geometry} material-color={diamondColor}>
                        <MeshRefractionMaterial envMap={map} aberrationStrength={0.005} toneMapped={false} />
                    </mesh>
                else
                    return <mesh key={v.name} geometry={v.geometry} material={v.material} material-color={metalColor}>
                    </mesh>
            })}
            {node_body2.map(v => {
                if (v.name.indexOf("_diamonds") > -1)
                    return <mesh key={v.name} geometry={v.geometry} material-color={diamondColor}>
                        <MeshRefractionMaterial envMap={map} aberrationStrength={0.005} toneMapped={false} />
                    </mesh>
                else
                    return <mesh key={v.name} geometry={v.geometry} material={v.material} material-color={metalColor}>
                    </mesh>
            })}
        </group>
    )
}

export default memo(Cufflinks);