import { useState, useEffect, useRef, Fragment } from "react";
import Header from "../../global/components/Header"
import style from "./index.module.scss";
import { Link, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'
import MessageBtn from "../../global/components/MessageBtn";
import NoticeBar from "../../global/components/NoticeBar";
import Myselect from "../../global/components/Myselect";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons'
import LaserCustom from "../../../Modal/LaserCoustom";
import { fetchPost } from "../../../comm/comm";
import { useTranslation } from "react-i18next";

const PreviewCustomText = ({ cart, setModalType }) => {
    const { t, i18n } = useTranslation();
    let { productsName, pid } = useParams();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [laserImg, setLaserImg] = useState('');
    const [laserType, setLaserType] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [textMaxLen, set_textMaxLen] = useState(9);
    const [balls, setBalls] = useState([{}]);
    useEffect(() => {
        console.log("productsName", productsName)
        const ballStr = localStorage.getItem('ball');
        const ballData = JSON.parse(ballStr);
        setBalls(ballData);
        setTotalPrice(ballData[0].price);
        if (productsName === "cuff") {
            if (ballStr) {
                console.log("ballStr", ballData[0])

                switch (ballData[0].id) {
                    //cuff
                    //正面
                    case '162':
                    case '163':
                        setLaserImg(`./images/laser/cuff_1.png`);
                        setLaserType(productsName + "_1");
                        set_textMaxLen(36);
                        break;
                    //裏面
                    case '164':
                    case '165':
                        setLaserImg(`./images/laser/cuff_2_engraved.png`);
                        setLaserType(productsName + "_2");
                        set_textMaxLen(18);
                        break;
                    case '166':
                    case '168':
                    case '167':
                    case '169':
                        setLaserImg(`./images/laser/cuff_2_diamond.png`);
                        setLaserType(productsName + "_2");
                        set_textMaxLen(18);
                        break;
                    //側面
                    case '170':
                    case "171":
                        setLaserImg(`./images/laser/cuff_3.png`);
                        setLaserType(productsName + "_3");
                        set_textMaxLen(18);
                        break;
                    //cufflink
                    //tiebar
                }
            }
        }
        else if (productsName === "tiebar") {
            setLaserImg(`./images/laser/tiebar.png`);
            setLaserType(productsName);
            set_textMaxLen(9);
        }
        else if (productsName === "cufflinks") {
            setLaserImg(`./images/laser/cufflinks.png`);
            setLaserType(productsName);
            set_textMaxLen(15);
        }
    }, [])

    function addCart(laser) {
        if (laser.message === "")
            return alert("沒有輸入雷雕文字");
        var obj = {};
        if (productsName === "cuff") {
            obj = {
                "cuffId": balls[0].id,
                "wristSize": balls[1].chineseName,
                "image": localStorage.getItem("tmppng").substring(22),
                "laser": laser
            }
        }
        else if (productsName === "tiebar") {
            obj = {
                "tiebarId": balls[0].id,
                "gemstoneId": balls[1].id,
                "image": localStorage.getItem("tmppng").substring(22),
                "laser": laser
            }
        }
        else if (productsName === "cufflinks") {
            obj = {
                "cufflinkId": balls[0].id,
                "gemstoneId": balls[1].id,
                "image": localStorage.getItem("tmppng").substring(22),
                "laser": laser
            }
        }
        const fnArr = {}
        setModalType(prev => [...prev, {
            cls: "Loading", arg: { fnArr: fnArr, processTime: 1000 }
        }])
        fetchPost(`/cart/${pid}?lang=${i18n.language}`, obj).then(res => {
            if (res.redirect) {
                fnArr.doCloseFn();
                if (isMobile) {
                    document.location.href = res.redirect;
                }
                else
                    window.open(res.redirect, '_blank');
            }
            else {
                fnArr.doCloseFn();
                console.log("res", res);
            }
        }).catch(err => {
            fnArr.doCloseFn();
            console.log("res error", err);
        });
    }
    return (
        <div className={style.app}>
            <NoticeBar />
            <Header setModalType={setModalType} cart={cart} />
            <div className={style.view}>
                <div className={style.topBtns} >
                    <div to={-1} className={style.back} onClick={e => {
                        document.location.href = `#/editor/${productsName}/${pid}/0/0`;
                        document.location.reload();
                    }}><p><FontAwesomeIcon icon={faChevronLeft} /> {t('backToEdit')}</p></div>
                    <Link to={'/'} className={style.close} ><FontAwesomeIcon icon={faClose} /></Link>
                </div>
                <h1>{t('step3WriteTitle')}</h1>
                <h2>{t('step3WriteSubTitle').format(localStorage.getItemByLang(i18n.language, "productName", pid)?.text)}</h2>
                <div className={style.LaserCustomView}>
                    <LaserCustom closeBtn={false} img={laserImg} laserType={laserType} onConfirm={(laser) => { addCart(laser) }} confirmStr={t('addToCart')} textMaxLen={textMaxLen} totalPrice={totalPrice} />
                </div>
            </div>
            <MessageBtn />
        </div>
    );
}

export default PreviewCustomText;