import * as THREE from "three";
import loadModel from "../util/model-loader";
class Comm {
    ballAssets = [
    ];
    allBall = [];

    constructor() {
    }
    disposeAll() {
        for (var k in this.allBall) {
            this.allBall[k].mesh.traverse(object => {
                if (!object.isMesh) return
                //console.log('dispose geometry!')
                object.geometry.dispose()

                if (object.material.isMaterial) {
                    this.cleanMaterial(object.material)
                } else {
                    // an array of materials
                    for (const material of object.material) this.cleanMaterial(material)
                }
            });
            this.allBall[k] = null;
        }
    }

    cleanMaterial = material => {
        //console.log('dispose material!')
        material.dispose()

        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                //console.log('dispose texture!')
                value.dispose()
            }
        }
    }
    getBall(name) {
        for (var k in this.allBall) {
            if (this.allBall[k].name == name) {
                var newBall = this.allBall[k].mesh.clone();
                //newBall.material = this.allBall[k].mesh.material.clone();
                newBall.traverse(function (child) {
                    if (child && child.isMesh) {
                        child.material = child.material.clone();
                    }
                });
                return newBall;
            }
        }
        return null;
    }
    getBallAssets(id) {
        for (var k in this.ballAssets) {
            if (this.ballAssets[k].id == id) {
                var ballAssets = Object.assign({}, this.ballAssets[k]);
                if (this.ballAssets[k].textureCount > 1)
                    ballAssets.modelPath = ballAssets.model + "_0" + (parseInt(Math.random() * this.ballAssets[k].textureCount) + 1) + '.gltf';
                else
                    ballAssets.modelPath = ballAssets.model + '.gltf';
                return ballAssets;

            }
        }
        return null;
    }
    getPureBallAssets(id) {
        for (var k in this.ballAssets) {
            if (this.ballAssets[k].id == id) {
                return this.ballAssets[k];
            }
        }
        return null;
    }
    getBaseMode(group) {
        for (var i = group.children.length - 1; i >= 0; i--) {
            var model = group.children[i];
            if(9==model.arg1)
            {
                return model;
            }
        }
    }
    getBaseModeForStringColor(group) {
        for (var i = group.children.length - 1; i >= 0; i--) {
            var model = group.children[i];
            if(5==model.arg1)
            {
                return model;
            }
        }
    }
    getFirstMesh(group) {
        if (group.isMesh) {
            return group;
        }
        else {
            return group.children[0];
        }
    }
    unselect(obj) {
        if (obj.isSelect) {
            obj.isSelect = false;
            console.log("child", obj)
            this.resetFilter(obj);
        }
        //obj.scale.set(1,1,1);
        /*  let mesh =  this.getFirseMesh(obj);
         mesh.material.emissive.setHex(0x000000); */
    }
    select(obj) {
        if (!obj.isSelect) {
            obj.isSelect = true;
            obj.traverse(function (child) {
                if (child && child.isMesh) {
                    child.material.emissive.setHex(0xff3333);
                }
            });
        }
        //obj.scale.set(1.2,1.2,1.2);
        /*  let mesh =  this.getFirseMesh(obj);
         mesh.material.emissive.setHex(0x333333); */
    }
    changeEmissiveColor(model, effects) {
        model.traverse(function (child) {
            if (child && child.isMesh) {
                for (var k in effects) {
                    switch (k) {
                        case "color":
                        case "emissive":
                            child.material[k].setHex("0x" + effects[k]);
                            break;
                        case "envMapIntensity":
                            child.material[k] = Number(effects[k]);
                            break;
                        case "roughness":
                            child.material[k] = Number(effects[k]);
                            break;
                    }
                }
            }
        });
    }
    isSelect(obj) {
        return obj.isSelect;
        if (obj.type == "Group" || obj.type == "Mesh") {
            console.log("obj.scale.x", obj.scale.x)
            return obj.scale.x == 1.2;
            let mesh = this.getFirseMesh(obj);
            return (mesh.material.emissive.getHex() == 3355443)
        }
        else
            return false;
    }
    resetFilter(model) {
        if (model.effects) {
            model.traverse(function (child) {
                if (child.isMesh) {
                    for (var k in model.effects) {
                        switch (k) {
                            case "color":
                            case "emissive":
                                child.material[k].setHex("0x" + model.effects[k]);
                                break;
                            case "envMapIntensity":
                                child.material[k] = Number(model.effects[k]);
                                break;
                            case "roughness":
                                child.material[k] = Number(model.effects[k]);
                                break;
                        }
                    }
                }
            });
        }
    }
    getMiddleBall(settingObj, children) {
        for (var k in children) {
            if (children[k].arg1 == 1)
                return children[k];
        }
        return null;
    }
    getBallByPos(settingObj, children,pos) {
        for (var k in children) {
            if (children[k].pos == pos)
                return children[k];
        }
        return null;
    }
    getTailBall(settingObj, children) {
        for (var k in children) {
            if (children[k].arg1 == 4)
                return children[k];
        }
        return null;
    }
    getConnectBall(settingObj, children) {
        let balls = [];
        for (var k in children) {
            if (children[k].arg1 == 3)
                balls.push(children[k]);
        }
        return balls;
    }
    loadTempBall(preloadBalls, pos, id) {
        for (var k in preloadBalls) {
            if (preloadBalls[k].pos == pos)
                return preloadBalls[k].id;
        }
        return id;
    }
    async loadGltf(id, pos, x, y, z, r1, ry, arg1) {
        let self = this;
        return new Promise(async (resolve, reject) => {
            var actionProcess = function (m) {
                /*if(nid==1||nid==0)
                r1=r1*1.5;*/

                /*else if(nid==6||nid==7||nid==8)
                r1=.7;*/
                m.scale.set(r1, r1, r1);
                m.position.x = x;
                m.position.z = z;
                m.position.y = y;
                //m.material.emissive.setHex(0x000000);
                //對向中心點的角度
                m.rotation.y = ry;
                //自體旋轉的角度 (為了看起來不同紋路)
                m.isSelect = false;
                /* m.traverse(function (child) {
                    if (child && child.isMesh) {
                        console.log("ballassets-----",ballassets.filter)
                        //child.material.emissive.setHex(0x000000);
                    }
                }); */
                m.rotation.z = ballassets.autoRotate == false ? 0 : .3 * parseInt(Math.random() * 4);
                m.nid = ballassets.id;
                m.price = ballassets.price;
                m.laser = ballassets.laser;
                m.laserTxt = "";
                m.image = ballassets.image;
                m.name = ballassets.name;
                m.arg1 = arg1;
                m.pos = pos;
                if (ballassets.effects)
                    m.effects = ballassets.effects;
                else
                    m.effects = { emissive: "000000" };
                self.resetFilter(m);

            }
            var ballassets = this.getBallAssets(id);
            var _ball = this.getBall(ballassets.modelPath);
            if (_ball) {
                actionProcess(_ball);
                resolve(_ball);
            }
            else {
                var _this = this;
                loadModel("models/" + ballassets.modelPath).then(model=>{
                    var newBall = model.clone();
                    //匯入之前 先把他clone，才不會因為actionProcess的參數調整，影響到原形
                    _this.allBall.push({ name: ballassets.modelPath, mesh: newBall });
                    actionProcess(model);
                    resolve(model);
                }).catch(e=>{
                    reject(e);
                });
                /*  const mainLight = new THREE.PointLight(0xffffff, 5, 2, 1);
                 mainLight.position.set(3, 2, 0);
                 model.add(mainLight); */
            }
        });
    }

}

export { Comm };