import { useState, useEffect, useRef, Fragment,useMemo } from "react";
import style from "./index.module.scss";
import Header from "../../global/components/Header"
import { Link, BrowserRouter } from "react-router-dom";
import { v4 } from "uuid";
import NoticeBar from "../../global/components/NoticeBar";
import { useMediaQuery } from 'react-responsive'
import MessageBtn from "../../global/components/MessageBtn";
import { useTranslation } from "react-i18next";
import { fetchGet } from "../../../comm/comm";
import { ProductImg } from "../../global/Styleds";

const Main = ({ cart, setCart,setModalType }) => {
    console.log(setCart)
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchGet('/home', {}, i18n.language,10).then(result => {
            console.log(result.categories)
            result.categories.forEach(v=>{
                if(v.url==='/Cufflinks')
                localStorage.setItemByLang(i18n.language,"productName","v8",{text:v.text});
            else if(v.url==='/Tiebar')
                localStorage.setItemByLang(i18n.language,"productName","v9",{text:v.text});
            })
            setData(result.categories);
            
        });
        console.log("api")
    }, [i18n.language])
   
    
    const onProductClick = item => () => {
        console.log("item", item);
        if (item.url === "/custom")
            document.location.href = `#/custom`;
        else if (item.url === "/Cufflinks" || item.url === "/Tiebar") {
            let pid = "v9", url = "#/editor/tiebar/v9/0/0";
            if (item.url === "/Cufflinks") {
                pid = "v8";
                url = `#/editor/cufflinks/v8/0/0`;
            }
            const tempSave = localStorage.getItemByLang(i18n.language,"tempSave",pid);
            if (tempSave) {
                setModalType(prev => [...prev, {
                    cls: "LoadRecord", arg: {
                        title: t('resumeRecordTitle'),
                        btns: [{
                            label: t('no'), cb: () => {
                                localStorage.setItemByLang(i18n.language,"tempSave",pid, "")
                                document.location.href = url;
                            }
                        }, {
                            label: t('yes'), cb: () => {
                                document.location.href = url;
                            }
                        }],
                        note: t('resumeRecordNote')
                    }
                }])
            }
            else
                document.location.href = url;
        }
        else
            document.location.href = `#/product${item.url}`;
    };
    console.log("hello")
    return (
        <div className={style.app}>
            <NoticeBar />
            <Header setModalType={setModalType}  cart={cart} />
            <h1>{t("step1Title")}</h1>
            <h2>{t("step1SubTitle")}</h2>
            <div className={isMobile ? style.productsMobile : style.products}>
                {
                    data.map(item => {
                        return <div key={v4()}><ProductImg img={item.image} onClick={onProductClick(item)}></ProductImg><span>{item.text}</span></div>
                    })
                }
            </div>
            <MessageBtn />
        </div>
    );
}

export default Main;