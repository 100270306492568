import { useState, useEffect, useRef, Fragment } from "react";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import allStyle from "../../global/all.module.scss";
const Page5 = ({ set_step }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={style.page5View}>
            <h1>{t(`customTitle_5`)}</h1>
            <h2>{t(`customSubTitle_5`)}</h2>
            <div className={style.btns}>
                <button className={allStyle.btnWhite} onClick={e => {
                    document.location.href = `#/`;
                }}>{t('backToHomepage')}</button>
                <button className={allStyle.btnBlack} onClick={e => {
                    document.location.reload();
                }}>{t('continueToDesign')}</button>
            </div>
        </div>
    );
}

export default Page5;