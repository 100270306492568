import { useState, useEffect, useRef, Fragment, useMemo } from "react";
import Header from "../../global/components/Header"
import style from "./index.module.scss";
import { Link, useParams } from "react-router-dom";
import { v4 } from "uuid";
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'
import MessageBtn from "../../global/components/MessageBtn";
import NoticeBar from "../../global/components/NoticeBar";
import Myselect from "../../global/components/Myselect";
import { customStyles, customModalStyles, ProductImg, SelProductImg, SelectDiv } from "../../global/Styleds";
import { fetchGet, fetchPost } from "../../../comm/comm";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from "react-i18next";
import allStyle from "../../global/all.module.scss";
const Product = ({ cart, currectProduct, setModalType }) => {
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [data, setData] = useState([]);
    const [h1txt, seth1text] = useState("");
    const [h2txt, seth2text] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    let { pid, fromOutside } = useParams();

    const [selItem, setSelItem] = useState({ item: { image: "", setting: { bead_size: [], wrist_size: [] } } });//對應下面的各種size，但這裡不用全列出來，反正是拿 server的值塞進來
    //select arg
    const [beadSize, setBeadSize] = useState("Select");
    const [wristSize, setWristSize] = useState("Select");
    const [middleBeadSize, setMiddleBeadSize] = useState("Select");
    const [necklaceLength, setNecklaceLength] = useState("Select");
    const [ringTempCount, set_ringTempCount] = useState(0);
    useEffect(() => {
        seth1text(t(`step2Title_${pid}`));
        seth2text(t(`step2SubTitle_${pid}`));

        const productsName = pid.toLowerCase() + "s";
        if (pid === "Ring") {
            const _data = [{ id: "r1" }, { id: "r2" }, { id: "r3" }];
            let hasData = false;
            let ringCount = 0;
            _data.forEach(ringObj => {
                const image1 = localStorage.getItemByLang(i18n.language, "tmpPNG", `${ringObj.id}/1`);
                const image2 = localStorage.getItemByLang(i18n.language, "tmpPNG", `${ringObj.id}/2`);
                const image3 = localStorage.getItemByLang(i18n.language, "tmpPNG", `${ringObj.id}/3`);
                const tempSave = localStorage.getItemByLang(i18n.language, "tempSave", `${ringObj.id}`);
                if (image1 && image2 && image3 && tempSave) {
                    ringObj.image = image3;
                    ringObj.allImage = [image1, image2, image3];
                    ringObj.tempSave = tempSave;
                    hasData = true;
                    ringCount++;
                }
            });
            set_ringTempCount(ringCount);
            if (fromOutside) {
                if (hasData) {
                    setModalType(prev => [...prev, {
                        cls: "LoadRecord", arg: {
                            title: t('keepData'),
                            btns: [{ label: t('cancel'), cb: () => { } }, {
                                label: t('discard'), cb: () => {
                                    localStorage.clearItemByLang(i18n.language, "tmpPNG", [`r1/1`, `r1/2`, `r1/3`, `r2/1`, `r2/2`, `r2/3`, `r3/1`, `r3/2`, `r3/3`]);
                                    localStorage.clearItemByLang(i18n.language, "tempSave", ["r1", "r2", "r3"]);
                                    setData([{ id: "r1" }, { id: "r2" }, { id: "r3" }]);
                                }
                            }],
                        }
                    }])
                }
            }
            setData(_data);
        }
        else {
            fetchGet(`/${productsName}`, {}, i18n.language, 10).then(result => {
                console.log(result[productsName])
                result[productsName].forEach(v => {
                    localStorage.setItemByLang(i18n.language, "productName", v.id, { text: v.name });
                })
                setData(result[productsName]);
            });
        }
    }, [i18n.language]);


    const onProductClick = item => () => {
        currectProduct.current = item;
        const productsName = pid.toLowerCase() + "s";
        function toSelect() {
            console.log("item", item);
            localStorage.setItem("productName", item.name);
            setBeadSize(t("select"));
            setWristSize(t("select"));
            setMiddleBeadSize(t("select"));
            setNecklaceLength(t("select"));
            fetchGet(`/${productsName}/${item.id}/size`, {}, "", 10).then(result => {
                console.log(result);
                if (result.msg) {
                    alert(result.msg);
                }
                else {
                    setSelItem({ item: item, setting: result });
                    setIsOpen(true);
                }
            });
        }
        const tempData = localStorage.getItemByLang(i18n.language, "tempSave", item.id);
        if (tempData) {
            let url = `#/editor/${productsName}/${item.id}/${tempData.product.arg}/${tempData.product.bead}`;
            let detail = [{ label: `${t('beadsize')}:`, value: tempData.product.bead }, { label: `${t('wristsize')}:`, value: tempData.product.arg }];
            switch (item.id) {
                case "v7":
                    url = `#/editor/cuff/v7/0/0`;
                    detail = [{ label: `${t('wristsize')}:`, value: tempData.selBall[1]?.name }]
                    break;
                case "v4":
                case "v5":
                    detail = [{ label: `${t('beadsize')}:`, value: tempData.product.bead }, { label: `${t('necklacesize')}:`, value: tempData.product.arg }];
                    break;
            }
            setModalType(prev => [...prev, {
                cls: "LoadRecord", arg: {
                    title: t('resumeRecordTitle'),
                    btns: [{
                        label: t('no'), cb: () => {
                            localStorage.setItemByLang(i18n.language, "tempSave", item.id, "")
                            toSelect();
                        }
                    }, {
                        label: t('yes'), cb: () => {
                            document.location.href = url;
                        }
                    }],
                    detail: detail,
                    note: t('resumeRecordNote')
                }
            }])
        }
        else {
            toSelect();
        }
    };
    function toEditClick(id) {
        /* console.log(id, wristSize, beadSize, middleBeadSize); */
        const productsName = pid.toLowerCase() + "s";
        if (pid == "Bracelet") {
            if (beadSize === t("select")) {
                return alert(t('BraceletBeadsizeOptionWaring'));
            }
            if (wristSize === t("select")) {
                return alert(t('BraceletLengthsizeOptionWaring'));
            }
            document.location.href = `#/editor/${productsName}/${id}/${wristSize}/${beadSize}`;
            document.location.reload();
        }
        else if (pid == "Necklace") {
            if (beadSize === t("select")) {
                return alert(t('necklaceBeadsizeOptionWaring'));
            }
            if (necklaceLength === t("select")) {
                return alert(t('necklaceLengthsizeOptionWaring'));
            }
            document.location.href = `#/editor/${productsName}/${id}/${necklaceLength}/${beadSize}`;
            document.location.reload();
        }
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }
    async function sendRingToCart() {
        var ringArr = [];
        const _data = [{ id: "r1" }, { id: "r2" }, { id: "r3" }];
        _data.forEach(ringObj => {
            const tempSave = localStorage.getItemByLang(i18n.language, "tempSave", `${ringObj.id}`);
            if (tempSave) {
                console.log("tempSave", tempSave)
                ringArr.push({
                    "gems": {
                        "type": "Diamond",
                        "shape": "Round",
                        "karat": tempSave.selBall[0].name,
                    },
                    "centerSetting": tempSave.selBall[1].name,
                    "mountingStyle": tempSave.selBall[2].name,
                    "color": tempSave.selBall[3].name,
                });
            }
        });
        if (ringArr.length !== 3)
            return;

        const obj = {
            "firstRing": ringArr[0],
            "secondRing": ringArr[1],
            "thirdRing": ringArr[2]
        }
        console.log("obj", obj);
        const fnArr = {}
        setModalType(prev => [...prev, {
            cls: "Loading", arg: { fnArr: fnArr, processTime: 1000 }
        }])
        fetchPost(`/cart/rings-try-on?lang=${i18n.language}`, obj).then(res => {
            if (res.redirect) {
                fnArr.doCloseFn();
                if (isMobile)
                    document.location.href = res.redirect;
                else
                    window.open(res.redirect, '_blank');
                localStorage.clearItemByLang(i18n.language, "tmpPNG", [`r1/1`, `r1/2`, `r1/3`, `r2/1`, `r2/2`, `r2/3`, `r3/1`, `r3/2`, `r3/3`]);
                localStorage.clearItemByLang(i18n.language, "tempSave", ["r1", "r2", "r3"]);
                set_ringTempCount(0);
            }
            else {
                fnArr.doCloseFn();
                console.log("res", res);
            }
        }).catch(err => {
            fnArr.doCloseFn();
            console.log("res error", err);
        });
    }

    function showRings() {
        return data.map(item => {
            if (item.tempSave) {
                return <div key={v4()}>
                    <div className={style.imgBox}>
                        <img src={item.image} onClick={
                            e => {
                                setModalType(prev => [...prev, {
                                    cls: "LoadRecord2", arg: {
                                        title: t('discardRing'),
                                        btns: [{
                                            label: t('ringKeepRecordNo'),
                                            cb: () => {
                                                localStorage.clearItemByLang(i18n.language, "tmpPNG", [`${item.id}/1`, `${item.id}/2`, `${item.id}/3`]);
                                                localStorage.clearItemByLang(i18n.language, "tempSave", [item.id]);
                                                setData(prev => {
                                                    const arr = [...prev];
                                                    const findItem = arr.find(v => v.id === item.id);
                                                    delete findItem.image;
                                                    delete findItem.allImage;
                                                    delete findItem.tempSave;
                                                    return arr;
                                                });
                                                set_ringTempCount(prev => prev - 1);
                                            }

                                        }, {
                                            label: t('ringKeepRecordYes'),
                                            cb: () => { }
                                        }],
                                    }
                                }])
                            }
                        } />
                    </div>
                    <span>{t('customAzuroRing')}</span>
                    <div className={style.viewList} onClick={e => {
                        setModalType(prev => [...prev, {
                            cls: "RingItemList", arg: { tempSave: item.tempSave, allImage: item.allImage }
                        }])
                    }}>
                        <span>{t('seeItemList')} </span>
                        <ArrowForwardIosIcon fontSize="10" />
                    </div>
                </div>
            }
            else {
                return <div key={v4()}>
                    <div className={style.imgBox}>
                        <img src={"./images/emptyRing.png"} onClick={e => {
                            document.location.href = `#/editor/ring/${item.id}/0/0`;
                            document.location.reload();
                        }} />
                    </div>
                </div>
            }
        })
    }
    
    function ShowSelect(props) {
        console.log("props",props)
        let url = "";
        if (props.id == "v1")
            url = i18n.language === "en" ? "https://azuro-republic.com/pages/bracelet-size-guide/?utm_source=3D-custom-app&utm_medium=size-guide-charm-bracelets" : "https://azuro-republic.com.tw/pages/wrist-size-guide";
        else if (props.id == "v2")
            url = i18n.language === "en" ? "https://azuro-republic.com/pages/bracelet-size-guide/?utm_source=3D-custom-app&utm_medium=size-guide-elastic-bracelets" : "https://azuro-republic.com.tw/pages/wrist-size-guide";
        else if (props.id == "v3")
            url = i18n.language === "en" ? "https://azuro-republic.com/pages/bracelet-size-guide/?utm_source=3D-custom-app&utm_medium=size-guide-braided-bracelets" : "https://azuro-republic.com.tw/pages/wrist-size-guide";
        else if (props.id == "v4")
            url = i18n.language === "en" ? "https://azuro-republic.com/pages/necklace-size-guide" : "https://azuro-republic.com.tw/pages/necklace-size-guide";
        else if (props.id == "v5")
            url = i18n.language === "en" ? "https://azuro-republic.com/pages/necklace-size-guide" : "https://azuro-republic.com.tw/pages/necklace-size-guide";
        else if (props.id == "v7") {
            //這個不用選，直接進入
            const productsName = pid.toLowerCase();
            document.location.href = `#/editor/cuff/v7/0/0`;
        }
        if (pid == "Bracelet") {
            return <Fragment><SelectDiv width={"100%"}>
                <label className={style.SelectLabel}>{t("beadsize")}:</label>
                <Myselect options={selItem.setting.beadSize} defaultSelect={beadSize} setValue={setBeadSize} />
            </SelectDiv>
                <div className={style.SelectDiv_row}>
                    <SelectDiv width={"50%"}>
                        <label className={style.SelectLabel}>{t("wristsize")}:</label>
                        <Myselect options={selItem.setting.wristSize} defaultSelect={wristSize} setValue={setWristSize} />
                    </SelectDiv><a className={style.SelectGuide} target="_blank" href={url}>{t("sizeguide")}</a></div>
            </Fragment>;
        }
        else if (pid == "Necklace") {
            return <Fragment><SelectDiv width={"100%"}>
                <label className={style.SelectLabel}>{t("beadsize")}:</label>
                <Myselect options={selItem.setting.beadSize} defaultSelect={beadSize} setValue={setBeadSize} />
            </SelectDiv>
                <div className={style.SelectDiv_row}>
                    <SelectDiv width={"50%"}>
                        <label className={style.SelectLabel}>{t("necklaceLengthsize")}:</label>
                        <Myselect options={selItem.setting.necklaceLength} defaultSelect={necklaceLength} setValue={setNecklaceLength} />
                    </SelectDiv><a className={style.SelectGuide} target="_blank" href={url}>{t("sizeguide")}</a></div>
            </Fragment>;
        }
        else {
            return <div />;
        }
    }

    function save() {
        const tempSaveStr = localStorage.getItem("tempSave");
        if (tempSaveStr) {
            let tempSave = JSON.parse(tempSaveStr);
            document.location.href = `#/editor/${tempSave.product.productsName}/${tempSave.product.pid}/${tempSave.product.arg}/${tempSave.product.bead}`;
            document.location.reload();
        }
    }

    return (
        <div className={style.app}>
            <NoticeBar />
            <Header setModalType={setModalType} save={save} cart={cart} />
            <h1>{h1txt}</h1>
            <h2 style={{padding:"0 1rem"}}>{h2txt}</h2>
            {pid === "Ring" ?
                <div className={isMobile ? style.ringProductsMobile : style.ringProducts}>
                    {showRings()}
                </div>
                :
                <div className={isMobile ? style.productsMobile : style.products}>
                    {
                        data.map(item => {
                            return <div key={v4()}><ProductImg img={item.image} onClick={onProductClick(item)}></ProductImg><span>{item.name}</span></div>
                            //return <Link to={`/editor`} key={v4()} ><div /* onClick={(item)=>{fn(item)}} */>{item}</div></Link>
                        })
                    }
                </div>
            }
            {ringTempCount >= 3 &&
                <button className={`${allStyle.btnBlack} ${style.cartBtn}`} onClick={e => {
                    sendRingToCart();
                }}>{t('addToCart')}</button>
            }
            <MessageBtn />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={isMobile ? customModalStyles : customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal"
            >
                <SelProductImg img={selItem.item.image} isMobile={isMobile} ></SelProductImg>
                <div className={style.modal_detail}>
                    <span>{selItem.item.name}</span>
                    <ShowSelect id={selItem.item.id} />
                    <button onClick={e => { toEditClick(selItem.item.id) }}>{t("next")}</button>
                </div>
            </Modal>
        </div>
    );
}

export default Product;