import { useState, useEffect, useRef, Fragment } from "react";
import style from "./NoticeBar.module.css";
import styleMobile from "./NoticeBarMobile.module.css";
import { useMediaQuery } from 'react-responsive'
import { fetchGet } from "../../../comm/comm";
import { useTranslation } from "react-i18next";

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}
const NoticeBar = () => {
    const { t, i18n } = useTranslation();
    const [announcements, set_announcements] = useState('');
    useEffect(() => {
        if (i18n.language !== "") {
            fetchGet('/announcements', {}, i18n.language, 10).then(result => {
                set_announcements(result.announcements[0].content)
            });
        }
    }, [i18n.language])
    if(announcements!=="")
    return (
        <Fragment>
            <Default>
                <div className={style.app}>
                    <div dangerouslySetInnerHTML={{ __html: announcements }} />
                </div>
            </Default>
            <Mobile>
                <div className={styleMobile.app}>
                    <div dangerouslySetInnerHTML={{ __html: announcements }} />
                </div>
            </Mobile>
        </Fragment>
    );
}

export default NoticeBar;