import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
const originalLog = console.log;

// 重写 console.log 函数
console.log = function(...message) {
    // 在输出前，你可以添加任何你需要的自定义逻辑
    // 这里只是简单地输出 "Overridden: " 字样
    //originalLog("Overridden: ",message);
};
Storage.prototype.getItemByLang = function (lang, name, pid) {
    const pathStr = `${lang}_${name}_${pid}`;
    const str = localStorage.getItem(pathStr);
    try {
        if (str && str !== "") {
            const _words = JSON.parse(str);
            return _words;
        }
    } catch (e) {
        alert("解析錯誤");
    }
}

Storage.prototype.setItemByLang = function (lang, name, pid, obj) {
    const pathStr = `${lang}_${name}_${pid}`;
    console.log(">>>>>>>>>>>>setItemByLang", pathStr);
    localStorage.setItem(pathStr, JSON.stringify(obj));
}

Storage.prototype.clearItemByLang = function (lang, name, pids) {
    pids.forEach(pid => {
        const pathStr = `${lang}_${name}_${pid}`;
        console.log(">>>>>>>>>>>>setItemByLang", pathStr);
        localStorage.setItem(pathStr, '');
    });
}

String.prototype.format = function () {
    var val1 = arguments;
    return this.replace(/{(\d+)}/g, function (get, number) {
      return typeof val1[number] != "undefined" ? val1[number] : get;
    });
  };