class CustomEventManager {
    constructor() {
        if (!CustomEventManager.instance) {
            this.listeners = new Map();
            CustomEventManager.instance = this;
        }

        return CustomEventManager.instance;
    }

    addEventListener(eventType, listener) {
        if (!this.listeners.has(eventType)) {
            this.listeners.set(eventType, []);
        }

        this.listeners.get(eventType).push(listener);
    }

    removeEventListener(listener) {
        // 遍歷所有事件類型的監聽器陣列，並刪除指定的監聽器
        this.listeners.forEach((listeners, eventType) => {
            this.listeners.set(
                eventType,
                listeners.filter(existingListener => existingListener !== listener)
            );
        });
    }

    dispatch(eventType, data) {
        // 將事件類型傳遞給監聽器，不再需要 data 參數
        if (this.listeners.has(eventType)) {
            const listeners = this.listeners.get(eventType);
            listeners.forEach(listener => {
                listener(data); // 不再傳遞 data
            });
        }
    }
}

export { CustomEventManager };
