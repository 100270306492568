import { useState, useRef, useEffect, memo } from 'react'
import { CustomEventManager } from '../../global/CustomEventManager'
import { useTranslation } from "react-i18next";
import { createStateMachine, wait } from '../../../comm/comm';
const customEventManager = new CustomEventManager();
const TeachManager = ({ isMobile, productsName, pid, canvasTmp, setBtnEnable, setTeachTextA, changeBtn, set_laserModalData }) => {
    const { t, i18n } = useTranslation();
    let stateFn = null;
    useEffect(() => {
        if (pid === "v1" || pid === "v2" || pid === "v5" || pid === "v6") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "middle", "otherAll", "changeTag_LASER:", "laserKeyIn"]);
            customEventManager.addEventListener("teach", teachActionV1256);
        }
        else if (pid === "v4") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "pendants", "otherAll", "changeTag_LASER:", "laserKeyIn"]);
            customEventManager.addEventListener("teach", teachActionV4);
        }
        else if (pid === "v3") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "middle", "otherAll", "changeToggle_TAIL BEADS:", "tailBead", "changeToggle_STRING COLOR:", "stringColor", "changeTag_LASER:", "laserKeyIn"]);
            customEventManager.addEventListener("teach", teachActionV3);
        }
        else if (pid === "v7") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "selStyle", "selSize"]);
            customEventManager.addEventListener("teach", teachActionV7);
        }
        else if (pid === "v8" || pid === "v9") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "selStyle", "selGem"]);
            customEventManager.addEventListener("teach", teachActionV89);
        }
        else if (productsName === "ring") {
            stateFn = createStateMachine(["ready", "rotate", "move", "reset", "selRing", "selCenter", "selMouting", "selRingColor"]);
            customEventManager.addEventListener("teach", teachActionRing);
        }
        customEventManager.addEventListener("threejs", threejsAction);
        return () => {
            if (pid === "v1" || pid === "v2" || pid === "v5" || pid === "v6") {
                customEventManager.removeEventListener("teach", teachActionV1256);
            }
            else if (pid === "v4") {
                customEventManager.removeEventListener("teach", teachActionV4);
            }
            else if (pid === "v3") {
                customEventManager.removeEventListener("teach", teachActionV3);
            }
            else if (pid === "v7") {
                customEventManager.removeEventListener("teach", teachActionV7);
            }
            else if (pid === "v8" || pid === "v9") {
                customEventManager.removeEventListener("teach", teachActionV89);
            }
            else if (productsName === "ring") {
                customEventManager.removeEventListener("teach", teachActionRing);
            }
            customEventManager.removeEventListener("threejs", threejsAction);
        };
    }, []);
    const threejsAction = async (data) => {
        console.log("threejs....data", data)
        switch (data.action) {
            case "putBall":
                if (data.ball.laser)
                    set_laserModalData({ img: `./assets/${data.ball.image}.png`, ball: data.ball, pos: data.pos })
                break;
            case "changeToggle":
                setTeachTextA("");
                if (canvasTmp.obj)
                    canvasTmp.obj.teachOtherTail("000000");
                break;
            case "changeTag":
                setTeachTextA("");
                localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                break;
        }
    }
    const teachActionV1256 = async (data) => {
        console.log("teach....data", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    if (pid === "v5")
                        setTeachTextA(t('teachRotateNecklace' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(t('teachRotateBracelet' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    if (pid === "v5")
                        setTeachTextA(isMobile ? t('teachMoveNecklaceMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveNecklace' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(isMobile ? t('teachMoveBraceletMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveBracelet' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    if (pid === "v6")
                        setTeachTextA(t('teachDrag2Clasp' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(t('teachDrag2Middle' + (isMobile ? "_mobile" : "")));
                    await wait(100);
                    canvasTmp.obj.teachMiddleBeads();
                    break;
                case "middle":
                    changeBtn("OTHER BEADS");
                    if (pid === "v5")
                        canvasTmp.obj.teachOtherBeadsForV4V5();
                    else
                        canvasTmp.obj.teachOtherBeads();
                    await wait(100);
                    setTeachTextA(t('teachDrag2Sec' + (isMobile ? "_mobile" : "")));
                    break;
                case "otherAll":
                    setBtnEnable(true);
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
                case "changeTag_LASER":
                    await wait(100);
                    setTeachTextA(t('teachLaser' + (isMobile ? "_mobile" : "")));
                    break;
                case "laserKeyIn":
                    setTeachTextA("");
                    break;
            }
        }
    }
    const teachActionV3 = async (data) => {
        console.log("teach....data", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    setTeachTextA(t('teachRotateBracelet' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    setTeachTextA(isMobile ? t('teachMoveBraceletMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveBracelet' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    setTeachTextA(t('teachDrag2Middle' + (isMobile ? "_mobile" : "")));
                    canvasTmp.obj.teachMiddleBeads();
                    break;
                case "middle":
                    changeBtn("OTHER BEADS");
                    canvasTmp.obj.teachOtherBeads();
                    await wait(100);
                    setTeachTextA(t('teachDrag2Sec' + (isMobile ? "_mobile" : "")));
                    break;
                case "otherAll":
                    setBtnEnable(true);
                    setTeachTextA("");
                    changeBtn("TAIL BEADS");
                    break;
                case "changeToggle_TAIL BEADS":
                    await wait(100);
                    canvasTmp.obj.teachOtherTail();
                    setTeachTextA(t('teachDrag2Tail' + (isMobile ? "_mobile" : "")));
                    break;
                case "tailBead":
                    setTeachTextA("");
                    changeBtn("STRING COLOR");
                    break;
                case "changeToggle_STRING COLOR":
                    setTeachTextA(t('teachStringColor' + (isMobile ? "_mobile" : "")));
                    break;
                case "stringColor":
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
                case "changeTag_LASER":
                    await wait(100);
                    setTeachTextA(t('teachLaser' + (isMobile ? "_mobile" : "")));
                    break;
                case "laserKeyIn":
                    setTeachTextA("");
                    break;
            }
        }
    }
    const teachActionV4 = async (data) => {
        console.log("teach. v4...data", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    setTeachTextA(t('teachRotateNecklace' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    setTeachTextA(isMobile ? t('teachMoveNecklaceMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveNecklace' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    setTeachTextA(t('teachDragPendant' + (isMobile ? "_mobile" : "")));
                    canvasTmp.obj.teachPendant();
                    break;
                /*   */
                case "pendants":
                    changeBtn("BEADS");
                    canvasTmp.obj.teachOtherBeadsForV4V5();
                    await wait(100);
                    setTeachTextA(t('teachDrag2Sec' + (isMobile ? "_mobile" : "")));
                    break;
                case "otherAll":
                    setBtnEnable(true);
                    await wait(100);
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
                case "changeTag_LASER":
                    await wait(100);
                    setTeachTextA(t('teachLaser' + (isMobile ? "_mobile" : "")));
                    break;
                case "laserKeyIn":
                    setTeachTextA("");
                    break;
            }
        }
    }
    const teachActionV7 = async (data) => {
        console.log("teach....data", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    setTeachTextA(t('teachRotateCuff' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    setTeachTextA(isMobile ? t('teachMoveCuffMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveCuff' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    setTeachTextA(t('teachSelStyleCuff' + (isMobile ? "_mobile" : "")));
                    break;
                case "selStyle":
                    changeBtn("WRIST SIZE");
                    await wait(100);
                    setTeachTextA(t('teachSelSizeCuff' + (isMobile ? "_mobile" : "")));
                    break;
                case "selSize":
                    setBtnEnable(true);
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
            }
        }
    }
    const teachActionV89 = async (data) => {
        console.log("teachActionV89", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    if (pid === "v9")
                        setTeachTextA(t('teachRotateTiebar' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(t('teachRotateCufflinks' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    if (pid === "v9")
                        setTeachTextA(isMobile ? t('teachMoveTiebarMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveTiebar' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(isMobile ? t('teachMoveCufflinksMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveCufflinks' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    if (pid === "v9")
                        setTeachTextA(t('teachSelStyleMetalTiebar' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(t('teachSelStyleMetalCufflinks' + (isMobile ? "_mobile" : "")));
                    break;
                case "selStyle":
                    changeBtn("GEMSTONE");
                    await wait(100);
                    if (pid === "v9")
                        setTeachTextA(t('teachSelStyleTiebar' + (isMobile ? "_mobile" : "")));
                    else
                        setTeachTextA(t('teachSelStyleCufflinks' + (isMobile ? "_mobile" : "")));
                    break;
                case "selGem":
                    setBtnEnable(true);
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
            }
        }
    }
    const teachActionRing = async (data) => {
        console.log("teach....data", data)
        if (stateFn(data.action)) {
            switch (data.action) {
                case "ready":
                    setBtnEnable(false);
                    setTeachTextA(t('teachRotateRing' + (isMobile ? "_mobile" : "")));
                    break;
                case "rotate":
                    setTeachTextA(isMobile ? t('teachMoveRingMobile' + (isMobile ? "_mobile" : "")) : t('teachMoveRing' + (isMobile ? "_mobile" : "")));
                    break;
                case "move":
                    setTeachTextA(t('teachResetAngle' + (isMobile ? "_mobile" : "")));
                    break;
                case "reset":
                    setTeachTextA(t('teachSelStyleRing' + (isMobile ? "_mobile" : "")));
                    break;
                case "selRing":
                    changeBtn("CENTER SETTING");
                    await wait(100);
                    setTeachTextA(t('teachSelStyleRingCenter' + (isMobile ? "_mobile" : "")));
                    break;
                case "selCenter":
                    changeBtn("MOUTING STYLE");
                    await wait(100);
                    setTeachTextA(t('teachSelStyleRingMouting' + (isMobile ? "_mobile" : "")));
                    break;
                case "selMouting":
                    changeBtn("METAL COLORS");
                    await wait(100);
                    setTeachTextA(t('teachSelStyleRingMetalColor' + (isMobile ? "_mobile" : "")));
                    break;
                case "selRingColor":
                    setBtnEnable(true);
                    setTeachTextA("");
                    localStorage.setItemByLang(i18n.language, "showTech", (productsName === "ring") ? "ring" : pid, "1");
                    break;
            }
        }
    }
    return (
        <div>{ }</div>
    )
}

export default memo(TeachManager);