import { useState, useEffect, useRef } from "react";

const Buttons = ({style,buttonsData,changeBtn}) =>{
    const buttonRefs = useRef([]);
    useEffect(() => {
        buttonsData.forEach((element, index) => {
          if (element.selected && buttonRefs.current[index]) {
            buttonRefs.current[index].scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
          }
        });
      }, [buttonsData]);
      
    return (
        <div className={style.buttons}>
            {
                buttonsData.map((item, index)=>{
                return <button key={index} onClick={()=>{changeBtn(item.id)}} 
                className={item.selected?style.buttonSelect:style.button}
                ref={(el) => (buttonRefs.current[index] = el)}>
                    {item.displayName}
                </button>})
            }
        </div>
      );
}

export default Buttons;