import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import style from "./CustomLaserImg.module.scss";
import CurveText from "./CurveText";
import ReactCurvedText from 'react-curved-text';
import { useMediaQuery } from 'react-responsive'
import { drawCurevd } from "../../../../comm/comm";

const LaserLabel = styled.div`
    font-family: ${props => props.fontfamily};
    font-size: ${props => props.size}px;
    color: ${props => props.color};
    text-transform:uppercase;
    position: absolute;
    top: ${props => props.top}px;
    left:${props => props.left}px;
    text-align: center;
    width:300px;
    font-weight: bold;
    `;
const CustomLaserImg = ({ laserType, displayImg, displayTxt, fontfamily }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const curvedRef = useRef(null);
    const curvedRef2 = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    async function loadFont(fontName, fontUrl) {
        var font = new FontFace(fontName, `url(${fontUrl})`);
        const loadedFont = await font.load();
        document.fonts.add(loadedFont);
    }
    function draw() {
        if (curvedRef.current) {
            const _text = displayTxt.toUpperCase();
            const str1 = _text.substr(0, 18);
            const str2 = _text.substr(18, 18);
            let initY, initY2;
            switch (laserType) {
                case "bracelets":
                case "necklaces":
                    drawCurevd('layer0', curvedRef.current, _text, fontfamily, windowWidth, 50, "white", [140, 230,
                        230, 260,
                        300, 260,
                        440, 238]);
                    break;
                case "cuff_1":
                    initY = 210;
                    initY2 = 230;
                    if (str2 !== "")
                        initY = 195;
                    drawCurevd('layer0', curvedRef.current, str1, fontfamily, windowWidth, 26, "white", [70, initY,
                        130, initY,
                        300, initY,
                        540, initY]);
                    drawCurevd('layer1', curvedRef2.current, _text.substr(18, 18), fontfamily, windowWidth, 26, "white", [70, initY2,
                        130, initY2,
                        300, initY2,
                        540, initY2]);
                    break;
                case "cuff_2":
                    drawCurevd('layer0', curvedRef.current, _text, fontfamily, windowWidth, 32, "white", [80, 175,
                        130, 170,
                        300, 160,
                        540, 200]);
                    break;
                case "cuff_3":
                    drawCurevd('layer0', curvedRef.current, _text, fontfamily, windowWidth, 21, "white", [50, 300,
                        130, 302,
                        300, 323,
                        500, 278]);
                    break;
                case "cufflinks":
                    initY = 240;
                    drawCurevd('layer0', curvedRef.current, _text, fontfamily, windowWidth, 30, "white", [70, initY,
                        130, initY,
                        300, initY,
                        510, initY]);
                    break;
                case "tiebar":
                    initY = 230;
                    drawCurevd('layer0', curvedRef.current, _text, fontfamily, windowWidth, 24, "white", [235, initY,
                        400, initY,
                        420, initY,
                        450, initY]);
                    break;
            }

        }
    }
    useEffect(() => {
        
        document.fonts.ready.then(() => {
            draw();
        }).catch(() => {
            console.error('Some fonts failed to load.');
        });
    }, [displayTxt, fontfamily, windowWidth]);

    const getFontSize = (min, max) => {
        if (windowWidth > 767)
            return max;
        else {
            return max - ((767 - windowWidth) / ((767 - 280) / (max - min)));
        }
    }

    return (
        <div className={style.app}>
            <img draggable="false" /* className={getStyle()} */ src={displayImg} />
            <div style={{ position: "absolute" }} ref={curvedRef} />
            <div style={{ position: "absolute" }} ref={curvedRef2} />
        </div>
    );
}

export default CustomLaserImg;