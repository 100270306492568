/* import { GUI } from 'dat.gui'
import * as THREE from "three"; */
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class MyGui {
    product = {};
    ballui = [];
    constructor(main) {
        this.main = main;
        //this.gui = new GUI();
        this.setup();
    }
    addGui = () => {

    }

    showBallGui = (ball) => {
        /*  var mesh = this.main.comm.getFirstMesh(ball);
         for (var i = this.ballui.length - 1; i >= 0; i--)
             this.gui.remove(this.ballui[i]);
         this.ballui = [];
         const data2 = {
             color: mesh.material.color.getHex(),
             emissive: mesh.material.emissive.getHex(),
         };
         this.ballui[this.ballui.length] = this.gui.addColor(data2, 'color').onChange(this.handleColorChange(mesh.material.color));
         this.ballui[this.ballui.length] = this.gui.addColor(data2, 'emissive').onChange(this.handleColorChange(mesh.material.emissive));
         this.ballui[this.ballui.length] = this.gui.add(mesh.material, 'envMapIntensity', 0, 2).onChange(() => { this.main.render() });
         this.ballui[this.ballui.length] = this.gui.add(mesh.material, 'roughness', 0, 1).onChange(() => { this.main.render() });
         var obj = {
             show: function (s) {
                 const str = JSON.stringify(
                     { color: mesh.material.color.getHex().toString(16), emissive: mesh.material.emissive.getHex().toString(16), envMapIntensity: mesh.material.envMapIntensity.toFixed(2), roughness: mesh.material.roughness.toFixed(2) }
                 );
                 console.log("-----output-----", str);
                 alert(str);
             }
         };
         this.ballui[this.ballui.length] = this.gui.add(obj, "show").name("show"); */
    }
    handleColorChange(color) {
        let self = this;
        return function (value) {

            if (typeof value === 'string') {

                value = value.replace('#', '0x');

            }

            color.setHex(value);
            self.main.render();
        };
    }
    addVData(product) {
        this.product = product;
    }
    getCurrectProduct() {
        return this.product;
    }
    getToggleNameByType(type) {//type==arg,arg1
        for (var i = 0; i < this.product.toggleOptions.length; i++) {
            if (this.product.toggleOptions[i].type == type) {
                return this.product.toggleOptions[i];
            }
        }
    }
    getAllBead() {
        var arr = [];
        var _ballkey = [];
        for (var i = 0; i < this.product.toggleOptions.length; i++) {
            for (var j = 0; j < this.product.toggleOptions[i].tags.length; j++) {
                for (var b = 0; b < this.product.toggleOptions[i].tags[j].beads.length; b++) {
                    if (!_ballkey[this.product.toggleOptions[i].tags[j].beads[b].id]) {
                        arr.push(this.product.toggleOptions[i].tags[j].beads[b]);
                    }
                    if (this.product.toggleOptions[i].tags[j].beads[b].connect) {
                        //如果有連接珠
                        if (!_ballkey[this.product.toggleOptions[i].tags[j].beads[b].connect.id]) {
                            arr.push(this.product.toggleOptions[i].tags[j].beads[b].connect);
                        }
                    }

                }
            }
        }
        arr.push({
            id: "9527",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "1_36_WhitePopcornBead/1_36_WhitePopcornBead_01",
            textureCount: 1,
            autoRotate: 0,
            filter: ''
        });
        arr.push({
            id: "1001",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "1_0_default/1_0_default",
            textureCount: 1,
            autoRotate: 0,
            filter: '{"color":"d41b1b","emissive":"9d8686","envMapIntensity":"1.27","roughness":"0.08"}'
        });
        arr.push({
            id: "1002",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "2_0_LionCharm_default/2_0_LionCharm_default",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "1003",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "2_24_Connectors_default/2_24_Connectors_default",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "116",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "Alpha/Alpha",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "117",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "2_2_1_Enamel_Alpha/2_2_1_Enamel_Alpha",
            textureCount: 1,
            autoRotate: 0
        });

        arr.push({
            id: "diamond",
            name: "diamond",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "1_8_PavedDiamondOmegaCuff_W_Silver_2/1_8_W_Silver_Diamond",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "diamond2",
            name: "diamond2",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "1_8_PavedDiamondOmegaCuff_W_Silver_2/1_8_PavedDiamondOmegaCuff_W_Silver",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "192",
            name: "default",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "8_BeadChainBracelet_Alpha/8_BeadChainBracelet_Alpha",
            textureCount: 1,
            autoRotate: 0
        });
        /* arr.push({
            id: "112",
            name: "Connectors  Gold",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "2_24_Connectors_Gold/2_24_Connectors_Gold",
            textureCount: 1,
            autoRotate: 0
        });
        arr.push({
            id: "113",
            name: "Connectors  Silver",
            description: "",
            price: 0,
            laser: false,
            image: "",
            model: "2_24_Connectors_Silver/2_24_Connectors_Silver",
            textureCount: 1,
            autoRotate: 0
        }); */
        console.log("this.main.settingObj.v", this.main.settingObj.v)

        if (this.product.baseModal) {
            if (this.main.settingObj.v == "v3") {
                this.product.baseModal = '1-5_Beaded_String/1_5_BeadedString';
                arr.push({
                    id: "baseModel",
                    name: "BLACK",
                    description: "",
                    price: 0,
                    laser: false,
                    image: "",
                    model: this.product.baseModal,
                    textureCount: 1,
                    autoRotate: 0
                });
            }
            else {
                arr.push({
                    id: "baseModel",
                    name: "baseModel2",
                    description: "",
                    price: 0,
                    laser: false,
                    image: "",
                    model: this.product.baseModal,
                    textureCount: 1,
                    autoRotate: 0
                });
            }
        }
        return arr
    }
    addEvent = (function () {
        if (document.addEventListener) {
            return function (el, type, fn) {
                if (el && el.nodeName || el === window) {
                    el.addEventListener(type, fn, false);
                } else if (el && el.length) {
                    for (var i = 0; i < el.length; i++) {
                        //todo 這裡應該不用this
                        this.addEvent(el[i], type, fn);
                    }
                }
            };
        } else {
            return function (el, type, fn) {
                if (el && el.nodeName || el === window) {
                    el.attachEvent('on' + type, function () { return fn.call(el, window.event); });
                } else if (el && el.length) {
                    for (var i = 0; i < el.length; i++) {
                        //todo 這裡應該不用this
                        this.addEvent(el[i], type, fn);
                    }
                }
            };
        }
    })();
    async dragEnd(event) {
        if (this.main.dragBall.arg1 == 5) return;
        clearTimeout(this.main.timeout);
        var intersects = this.main.raycaster.intersectObjects(this.main.scene.children);
        if (intersects.length > 0) {
            let chArr = [];
            let pos = 0;
            for (var i = this.main.scene.children.length - 1; i >= 0; i--) {
                var currectTarget = this.main.scene.children[i];
                if (this.main.comm.isSelect(currectTarget)) {
                    this.main.scene.remove(currectTarget);
                    try {

                        this.main.scene.add(await this.main.comm.loadGltf(this.main.dragBall.nid, currectTarget.pos, currectTarget.position.x, currectTarget.position.y, currectTarget.position.z, currectTarget.scale.x, currectTarget.rotation.y, currectTarget.arg1));
                        pos = currectTarget.pos;
                        chArr.push(currectTarget.arg1);
                    } catch (e) {
                        this.main.scene.add(currectTarget);
                        alert("找不到資源:"+this.main.dragBall.nid)
                    }
                }
            }
            var ball = this.main.comm.getPureBallAssets(this.main.dragBall.nid);
            console.log("------------>", ball);
            if (chArr.length === 1)
                this.main.customEventManager.dispatch("threejs", { action: "putBall", ball: ball, pos: pos });
            console.log("chArr", chArr);
            console.log("currectTarget", currectTarget);
            if (chArr.length == 1 && chArr[0] == 1) {
                if (this.main.settingObj.v == "v4") {
                    this.main.customEventManager.dispatch("teach", { action: "pendants" });
                }
                else {
                    this.main.customEventManager.dispatch("teach", { action: "middle" });
                }
            }
            else if (chArr.length == 3 && this.main.settingObj.v == "v4") {
                this.main.customEventManager.dispatch("teach", { action: "pendants" });
            }
            else if (chArr.length > 1 && chArr[0] == 2) {
                this.main.customEventManager.dispatch("teach", { action: "otherAll" });
            }
            else if (chArr[0] == 4) {
                this.main.customEventManager.dispatch("teach", { action: "tailBead" });
            }
            //找出托曳珠，若是有laser，則跳出視窗，寫入laser的文字及屬性 存入該模型中
            //var ballassets = this.getBallAssets(id);
            this.main.checkBalls();
            this.main.clearSelect();
            this.main.render();
        }
        else {
            this.main.clearSelect();
            this.main.render();
        }
    }
    async dragMove(event) {
        if (this.main.dragBall.arg1 == 5) return;
        var offsetY = 0;
        var offsetX = 0;
        if (this.main.deviceType != "desktop") {
            offsetY = -30;
            offsetX = 0;
        }
        this.main.mouse.x = ((event.clientX + offsetX) / this.main.vp.width) * 2 - 1;
        this.main.mouse.y = - ((event.clientY + offsetY) / this.main.vp.height) * 2 + 1;
        this.main.raycaster.setFromCamera(this.main.mouse, this.main.camera);
        var intersectObj = null;
        if (this.main.scene.children) {
            var intersects = this.main.raycaster.intersectObjects(this.main.scene.children, true);
            for (var i = 0; i < intersects.length; i++) {
                if (intersects[i].object.isMesh && intersects[i].object.parent.nid && this.main.matchFn(intersects[i].object.parent)) {
                    intersectObj = intersects[i].object;
                    break;
                }
            }
        }
        if (intersectObj && this.main.matchFn(intersectObj.parent)) {//
            if (this.main.intersectedArr[0] != intersectObj) {
                if (this.main.intersectedArr[0] && this.main.intersectedArr.length == 1)
                    this.main.comm.unselect(this.main.intersectedArr[0].parent);
                this.main.intersectedArr[0] = intersectObj;
                this.main.comm.select(this.main.intersectedArr[0].parent);
                this.main.render();
                clearTimeout(this.main.timeout);
                if (this.main.dragBall.laser == false) {
                    this.main.timeout = setTimeout(() => {
                        this.main.intersectedArr = [];
                        for (var k in this.main.scene.children) {
                            if (this.main.matchFn(this.main.scene.children[k])) {
                                this.main.comm.select(this.main.scene.children[k]);
                                this.main.intersectedArr.push(this.main.scene.children[k]);
                            }
                        }
                        console.log(this.main.intersectedArr.length,this.main.intersectedArr)
                    }, 2000);
                }
            }
        }
        else {
            clearTimeout(this.main.timeout);
            this.main.clearSelect();
            this.main.render();
        }
    }
    getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    };
    async setup() {
        this.main.deviceType = this.getDeviceType();
        let self = this;
        this.addEvent(document.body, 'touchstart', function (event) {
        });
        this.addEvent(document.body, 'dragleave', function (event) {
            self.dragEnd(event);
        });
        this.addEvent(document.body, 'dragover', function (event) {
            self.dragMove(event);
        });
        if (this.main.deviceType != "desktop") {
            this.addEvent(document.body, 'drag', function (event) {
                self.dragMove(event);
            });
            this.addEvent(document.body, 'dragend', function (event) {
                self.dragEnd(event);
            });
        }

    }
}

export { MyGui };