import React, { Fragment } from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Main from "./desktop/pages/Main";
import Product from "./desktop/pages/Product";
import Editor from "./desktop/pages/Editor";
import Preview from "./desktop/pages/Preview";
import PreviewCustomText from "./desktop/pages/PreviewCustomText";
import { useMediaQuery } from 'react-responsive'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Custom from "./desktop/pages/Custom";
import { fetchGet } from "./comm/comm";
import Loading from "./Modal/Loading";
import RingItemList from "./Modal/RingItemList";
import LoadRecord from "./Modal/LoadRecord";
import LoadRecord2 from "./Modal/LoadRecord2";
import TeachModal from "./Modal/TeachModal";
import PhoneOptionModal from "./Modal/PhoneOptionModal";
import { useTranslation } from "react-i18next";
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 820 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 821 })
  return isNotMobile ? children : null
}

export default function App() {
  const { t, i18n } = useTranslation();
  const [cart, setCart] = useState([1, 2, 3]);
  const currectProduct = useRef(null);
  const [modalType, setModalType] = useState([]);
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (params.get("lang")) {
      let lang = params.get("lang");
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
    //資料太大 只能用localstorage cache
    const lastSaveTime = localStorage.getItem("beadsGetTime", new Date().getTime() + 60 * 60 * 1000);
    const diffTime = parseInt(lastSaveTime) - new Date().getTime();
    if (true/* !lastSaveTime||diffTime<0 */) {
      fetchGet("/beads", {}, "zh").then(result => {
        localStorage.setItem("beadsGetTime", new Date().getTime() + 60 * 60 * 1000);
        localStorage.setItem("beads", JSON.stringify(result.beads));
      });
    }
  }, [])


  let modalArr = useMemo(() =>
    modalType.map((v, k) => {
      console.log("modalType", v, k)
      if (v.cls == "Loading") {
        return <Loading key={k} arg={v.arg} setModalType={setModalType} />
      }
      else if (v.cls == "RingItemList") {
        return <RingItemList key={k} arg={v.arg} setModalType={setModalType} />
      }
      else if (v.cls == "LoadRecord") {
        return <LoadRecord key={k} arg={v.arg} setModalType={setModalType} />
      }
      else if (v.cls == "LoadRecord2") {
        return <LoadRecord2 key={k} arg={v.arg} setModalType={setModalType} />
      }
      else if (v.cls == "TeachModal") {
        return <TeachModal key={k} arg={v.arg} setModalType={setModalType} />
      }
      else if (v.cls == "PhoneOptionModal") {
        return <PhoneOptionModal key={k} arg={v.arg} setModalType={setModalType} />
      }
    }), [modalType]);
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main cart={cart} setModalType={setModalType} />} />
          <Route path="/custom" element={<Custom cart={cart} setModalType={setModalType} />} />
          <Route path="/product/:pid" element={<Product cart={cart} currectProduct={currectProduct} setModalType={setModalType} />} />
          <Route path="/product/:pid/:fromOutside" element={<Product cart={cart} currectProduct={currectProduct} setModalType={setModalType} />} />
          <Route path="/editor/:productsName/:pid/:arg/:bead" element={<Editor cart={cart} setModalType={setModalType} />} />
          <Route path="/preview/:productsName/:pid/:arg/:bead" element={<Preview cart={cart} currectProduct={currectProduct} setModalType={setModalType} />} />
          <Route path="/preview/:productsName/:pid" element={<PreviewCustomText cart={cart} setModalType={setModalType} />} />
        </Routes>
      </HashRouter>
      {modalArr}
    </>
  );
}


